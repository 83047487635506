/* eslint-disable sonarjs/cognitive-complexity*/
import './HeaderStyle.scss'

import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Flag_EN from '@/assets/img/fl_en.png'
import Flag_JP from '@/assets/img/fl_jp.png'
import Flag_KR from '@/assets/img/fl_kr.png'
import Flag_VN from '@/assets/img/fl_vn.png'
import LogoColor from '@/assets/img/LOGO_color.png'
// import LogoWhite from '@/assets/img/LOGO_white.png'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import { useToggle } from '@/components/CustomHook/useToggle'
import { IconList, IconRound } from '@/components/Icons'
import useOfflineRedirect from '@/hooks/useOfflineRedirect'

const Header: React.FC = () => {
  const { i18n, t } = useTranslation(['common'])

  const [togglenavMobile, handleToggleNavMobile] = useToggle(false)
  const [toggleProductMb, handleToggleProductMb] = useToggle(false)

  const { scrollToSection } = useScrollToSection()
  const isOnline = useOfflineRedirect()

  const getImageLanguageFlag = () => {
    switch (i18n.language) {
      case 'en':
        return Flag_EN
      case 'ja':
        return Flag_JP
      case 'vi':
        return Flag_VN
      case 'ko':
        return Flag_KR
      default:
        return Flag_EN
    }
  }

  const onChangeLanguage = (lang: string) => i18n.changeLanguage(lang)

  return (
    <header className={classNames('header sub-layout')}>
      <nav className="navbar">
        <div className="logo">
          <Link to={'/'}>
            <img src={LogoColor} height={42} />
          </Link>
        </div>
        <ul className="nav-list">
          <li>
            <Link to={isOnline ? '/' : ''}>{t('Home')}</Link>
          </li>
          <li className="header-dropdown">
            <Link to={''} className="show">
              {t('AI Products')} <IconRound width={16} height={16} />
            </Link>

            <div className={'header-dropdown-content'}>
              <Link to={isOnline ? '/ai-products' : ''}>{t('Overview')}</Link>
              <Link to={isOnline ? '/speech-to-text' : ''}>
                {t('Speech to Text')}
              </Link>
              <Link to={isOnline ? '/ocr' : ''}>{t('OCR')}</Link>
              <Link to={isOnline ? '/face-recognition' : ''}>
                {t('Face Recognition')}
              </Link>
              <Link to={isOnline ? '/chatbot-platform' : ''}>
                {t('Chatbot Platform')}
              </Link>
              <Link to={isOnline ? '/chatbot-template' : ''}>
                {t('Chatbot Template')}
              </Link>
              <Link to={isOnline ? '/large-language-model' : ''}>
                {t('Large Language Model')}
              </Link>
              <Link to={isOnline ? '/image-generator' : ''}>
                {t('Image Generator')}
              </Link>
            </div>
          </li>
          <li>
            <Link to={isOnline ? '/digital-transformation' : ''}>
              {t('Digital Transformation')}
            </Link>
          </li>

          <li className="header-dropdown">
            <Link to={''} className="show">
              {t('Blockchain')} <IconRound width={16} height={16} />
            </Link>

            <div className={'header-dropdown-content'}>
              <Link to={isOnline ? '/blockchain' : ''}>{t('Overview')}</Link>
              <Link to={isOnline ? '/nft-marketplace' : ''}>
                {t('NFT Marketplace')}
              </Link>
            </div>
          </li>

          <li className="header-dropdown">
            <Link to={''} className="show">
              {t('Game & Metaverse')} <IconRound width={16} height={16} />
            </Link>

            <div className={'header-dropdown-content'}>
              <Link to={isOnline ? '/game-metaverse' : ''}>
                {t('Overview')}
              </Link>
              <Link to={isOnline ? '/mera-office' : ''}>
                {t('Mera Office')}
              </Link>
            </div>
          </li>
          <li>
            <Link
              onClick={() => isOnline && scrollToSection('contact')}
              to={''}
            >
              {t('Contact')}
            </Link>
          </li>
          {/* <li>
            <a href={'/blog'}>{t('Blog')}</a>
          </li> */}

          <li className="language-icons header-dropdown">
            <Link to={''} className="show current-language">
              <img src={getImageLanguageFlag()} height={24} />{' '}
              <IconRound width={16} height={16} />
            </Link>

            <div className={'header-dropdown-content'}>
              <Link
                className="flag-item"
                to=""
                onClick={() => onChangeLanguage('en')}
              >
                <img src={Flag_EN} height={24} />
                {t('English')}
              </Link>
              <Link
                className="flag-item"
                to=""
                onClick={() => onChangeLanguage('ja')}
              >
                <img src={Flag_JP} height={24} />
                {t('Japanese')}
              </Link>
              <Link
                className="flag-item"
                to=""
                onClick={() => onChangeLanguage('vi')}
              >
                <img src={Flag_VN} height={24} />
                {t('Vietnamese')}
              </Link>
              <Link
                className="flag-item"
                to=""
                onClick={() => onChangeLanguage('ko')}
              >
                <img src={Flag_KR} height={24} />
                {t('Korean')}
              </Link>
            </div>
          </li>
        </ul>

        <button className="header-menu" onClick={handleToggleNavMobile}>
          <IconList width={24.8} height={18.92} />
        </button>
      </nav>
      <div className={'nav-mobile ' + (togglenavMobile ? 'active' : '')}>
        <ul className={'nav-list ' + (toggleProductMb && 'active')}>
          <li>
            <Link to={isOnline ? '/' : ''}>{t('Home')}</Link>
          </li>
          <li className="header-dropdown" onClick={handleToggleProductMb}>
            <span>
              <Link to={''}>{t('AI Products')}</Link>
              <IconRound width={16} height={16} />
            </span>
            <div className={'header-dropdown-content'}>
              <Link to={isOnline ? '/ai-products' : ''}>{t('Overview')}</Link>
              <Link to={isOnline ? '/speech-to-text' : ''}>
                {t('Speech to Text')}
              </Link>
              <Link to={isOnline ? '/ocr' : ''}>{t('OCR')}</Link>
              <Link to={isOnline ? '/face-recognition' : ''}>
                {t('Face Recognition')}
              </Link>
              <Link to={isOnline ? '/chatbot-platform' : ''}>
                {t('Chatbot Platform')}
              </Link>
              <Link to={isOnline ? '/chatbot-template' : ''}>
                {t('Chatbot Template')}
              </Link>
              <Link to={isOnline ? '/large-language-model' : ''}>
                {t('Large Language Model')}
              </Link>
              <Link to={isOnline ? '/image-generator' : ''}>
                {t('Image Generator')}
              </Link>
            </div>
          </li>
          <li>
            <Link to={isOnline ? '/digital-transformation' : ''}>
              {t('Digital Transformation')}
            </Link>
          </li>

          <li className="header-dropdown" onClick={handleToggleProductMb}>
            <span>
              <Link to={''}> {t('Blockchain')}</Link>
              <IconRound width={16} height={16} />
            </span>
            <div className={'header-dropdown-content'}>
              <Link to={isOnline ? '/blockchain' : ''}>{t('Overview')}</Link>
              <Link to={isOnline ? '/nft-marketplace' : ''}>
                {t('NFT Marketplace')}
              </Link>
            </div>
          </li>

          <li className="header-dropdown" onClick={handleToggleProductMb}>
            <span>
              <Link to={''}> {t('Game & Metaverse')}</Link>
              <IconRound width={16} height={16} />
            </span>
            <div className={'header-dropdown-content'}>
              <Link to={isOnline ? '/game-metaverse' : ''}>
                {t('Overview')}
              </Link>
              <Link to={isOnline ? '/speech-to-text' : ''}>
                {t('Mera Office')}
              </Link>
            </div>
          </li>

          <li>
            <Link
              onClick={() => isOnline && scrollToSection('contact')}
              to={''}
            >
              {t('Contact')}
            </Link>
          </li>
          {/* <li>
            <a href={'/blog'}>{t('Blog')}</a>
          </li> */}
          <li className="language-icons">
            <Languages onChangeLanguage={onChangeLanguage} />
          </li>
        </ul>
      </div>
    </header>
  )
}

const Languages: React.FC<{ onChangeLanguage: (lang: string) => void }> = ({
  onChangeLanguage,
}) => {
  return (
    <>
      <img src={Flag_JP} height={24} onClick={() => onChangeLanguage('ja')} />
      <img src={Flag_EN} height={24} onClick={() => onChangeLanguage('en')} />
      <img src={Flag_VN} height={24} onClick={() => onChangeLanguage('vi')} />
      <img src={Flag_KR} height={24} onClick={() => onChangeLanguage('ko')} />
    </>
  )
}

export default Header
