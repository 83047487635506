import './style.scss'

import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'

const Banner: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()

  return (
    <section id="banner">
      <div className="banner game_metaverse">
        <div className="content-container">
          <div className="content">
            <p className="title">{t('Game & Metaverse')}</p>
            <div className="child-content">
              <p>{t('game_metaverse_description')}</p>
            </div>
            <Button
              className="explore_more w-[160px] h-[48px]"
              onClick={() => scrollToSection('contact')}
            >
              {t('Contact')}
            </Button>
          </div>
          <div className="bg-game_metaverse bg-contain bg-no-repeat w-[500px] h-[350px]"></div>
        </div>
      </div>
    </section>
  )
}

export default Banner
