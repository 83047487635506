import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'
import { CATEGORIES } from '@/contants/categories'
import { useCategoryId } from '@/hooks/useCategoryId'

import CardSlider from '../../Item/CardSlider'
import AboutUs from '../AboutUs'
import Banner from './Banner'
import Introduce from './Introduce'
import Technologies from './Technologies'

const DigitalTransformation: React.FC = () => {
  const digitalTransformation = useCategoryId(CATEGORIES.DigitalTransformation)

  return (
    <Layout>
      <Banner />
      <Introduce />
      <Technologies />
      <CardSlider categoryId={digitalTransformation} />
      <AboutUs className="mt-[60px] mb-[60px]" />
    </Layout>
  )
}

export default DigitalTransformation
