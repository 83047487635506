import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageAwards from '@/assets/img/top/award.png'
import ImageMain from '@/assets/img/top/container.png'
import Button from '@/components/Common/Item/Button'

const Awards = () => {
  const { t } = useTranslation(['common'])

  return (
    <section className="awards" id="awards">
      <div className="main-container custom-container">
        <div className="content">
          <h2 className="title">{t('Awards')}</h2>
          <p className="description">{t('awards_description')}</p>

          <img src={ImageAwards} alt="awards" />
          <Button className="explore_more h-[48px]">{t('Explore more')}</Button>
        </div>

        <div className="main-image">
          <img src={ImageMain} alt="awards" />
        </div>
      </div>
    </section>
  )
}

export default Awards
