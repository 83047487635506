import './style.scss'

import classNames from 'classnames'
import { useEffect, useState } from 'react'

import IconArrowDown from '@/assets/img/speech2text/Caret_Down_MD.svg'

type data = {
  title: string
  description?: string
}

type PRFAQProps = {
  title: string
  description?: string
  image?: string
  data?: data[]
}

const PRFAQ: React.FC<PRFAQProps> = ({ title, description, image, data }) => {
  const [toggleContent, setToggleContent] = useState<{
    [key: number]: boolean
  }>({})

  useEffect(() => {
    const initToggleContent: { [key: number]: boolean } = Array.isArray(data)
      ? data.reduce((acc, _, index) => {
          acc[index] = true
          return acc
        }, {} as { [key: number]: boolean })
      : {}

    setToggleContent(initToggleContent)
  }, [data])

  const renderListItems = Array.isArray(data)
    ? data.map((item, index) => {
        return (
          <div className="wrapper-faq-content" key={index}>
            <div
              className={classNames('faq-content', {
                hide: !toggleContent[index],
              })}
            >
              <div className="faq-top">
                <p className="faq-index">0{index + 1}</p>
                <p className="faq-title">{item.title}</p>
                <img
                  src={IconArrowDown}
                  alt="Arrow down"
                  className="btn-icon-arrow-down"
                  onClick={() =>
                    setToggleContent({
                      ...toggleContent,
                      [index]: !toggleContent[index],
                    })
                  }
                />
              </div>
              <p className="faq-description">{item.description}</p>
            </div>
          </div>
        )
      })
    : []

  return (
    <section className="pr-faq" id="pr-faq">
      <div className="main-container custom-container">
        <h2 className="title">{title}</h2>

        {description && <p className="description">{description}</p>}

        {image && (
          <div className="image-box">
            <img src={image} alt={title} />
          </div>
        )}

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default PRFAQ
