import './style.scss'
import 'suneditor/dist/css/suneditor.min.css'

import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ProductDemo from '@/components/Common/Item/ProductDemoCard'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import {
  getCaseStudiesPostDetailAction,
  getCaseStudiesPostsAction,
} from '@/states/features/caseStudies/caseStudies.action'
import { useAppDispatch } from '@/states/store'
import { capitalizeFirstLetter } from '@/utils/common'

const CaseStudyDetail: React.FC = () => {
  const { i18n, t } = useTranslation(['common', 'validation'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [caseStudyDetail, setCaseStudyDetail] =
    useState<CaseStudies.CaseStudiesCardItem>()
  const [caseStudies, setCaseStudies] = useState<
    CaseStudies.CaseStudiesCardItem[]
  >([])
  const lang = capitalizeFirstLetter(i18n.language)

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  useEffect(() => {
    if (caseStudyDetail?.category?.id) {
      getCaseStudies(caseStudyDetail?.category?.id)
    }
  }, [caseStudyDetail])

  const fetchData = async () => {
    const response = await dispatch(
      getCaseStudiesPostDetailAction({ params: { postId: id || '' } })
    )
    const result = response?.payload?.data

    if (result) {
      setCaseStudyDetail(result)
    }
  }

  const getCaseStudies = async (categoryId?: string) => {
    const response = await dispatch(
      getCaseStudiesPostsAction({
        params: { limit: 3, categoryId: categoryId },
      })
    )
    const result = response?.payload?.data?.records

    if (result) {
      setCaseStudies(result)
    }
  }

  const listItemsCustom = () => {
    return (
      <Row gutter={[16, 16]} className="w-full">
        {caseStudies.map((item) => (
          <Col key={item.id} xs={24} sm={12} md={8}>
            <ProductDemo
              key={item.id}
              image={item.thumbnail}
              // tag={
              //   item.category[
              //     `name${lang}` as keyof CaseStudies.Category
              //   ] as string
              // }
              title={
                item[
                  `title${lang}` as keyof CaseStudies.CaseStudiesCardItem
                ] as string
              }
              onClick={() => navigate(`/case-study/${item.id}`)}
            />
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <Layout>
      <div className="case-study-detail">
        <div className="main-container custom-container">
          <div className="title">
            {' '}
            {
              caseStudyDetail?.[
                `title${lang}` as keyof CaseStudies.CaseStudiesCardItem
              ] as string
            }{' '}
          </div>
          <div
            className="wrapper-content-editor sun-editor-editable"
            dangerouslySetInnerHTML={{
              __html: caseStudyDetail?.[
                `content${lang}` as keyof CaseStudies.CaseStudiesCardItem
              ] as string,
            }}
          />

          <div className="wrapper-slider">
            <div className="slider-title">{t('Case study')}</div>
            {listItemsCustom()}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudyDetail
