import './style.scss'

import { Col, Row } from 'antd'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Search from '@/assets/svg/search.svg'
import Button from '@/components/Common/Item/Button'
import Input from '@/components/Common/Item/Input'
import Loading from '@/components/Common/Item/Loading'
import Pagination from '@/components/Common/Item/Pagination'
import ProductDemo from '@/components/Common/Item/ProductDemoCard'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import { getCaseStudiesPostsAction } from '@/states/features/caseStudies/caseStudies.action'
import { getCategoriesStatisticsAction } from '@/states/features/categories/categories.action'
import { useAppDispatch, useAppSelector } from '@/states/store'
import { capitalizeFirstLetter, cleanedObj } from '@/utils/common'

import PageTitle from '../../Item/PageTitleBg'

const CaseStudyList: React.FC = () => {
  const { i18n, t } = useTranslation(['common', 'validation'])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { caseStudiesPosts, isLoading } = useAppSelector(
    (state) => state.caseStudies
  )
  const { categoriesStatistics } = useAppSelector((state) => state.categories)
  const [currentPage, setCurrentPage] = useState(1)
  const [filter, setFilter] = useState({ keyword: '', categoryId: '' })
  const lang = capitalizeFirstLetter(i18n.language)
  useEffect(() => {
    getCategoriesStatistics()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter.categoryId])

  const fetchData = async (page = 1) => {
    await dispatch(
      getCaseStudiesPostsAction({
        params: cleanedObj({
          ...filter,
          page: Number(page),
          limit: 9,
          lang: i18n.language.toLocaleUpperCase(),
        }),
      })
    )
    setCurrentPage(page)
  }

  const getCategoriesStatistics = async (page = 1) => {
    await dispatch(getCategoriesStatisticsAction())
    setCurrentPage(page)
  }

  const listItems = () => {
    return (
      <Row gutter={[16, 16]} className="w-full">
        {caseStudiesPosts?.records?.map((item) => {
          return (
            <Col key={item.id} xs={24} sm={12} md={8}>
              <ProductDemo
                key={item.id}
                image={item.thumbnail}
                // tag={
                //   item.category[
                //     `name${lang}` as keyof CaseStudies.Category
                //   ] as string
                // }
                title={
                  item[
                    `title${lang}` as keyof CaseStudies.CaseStudiesCardItem
                  ] as string
                }
                onClick={() => navigate(`/case-study/${item.id}`)}
              />
            </Col>
          )
        })}
      </Row>
    )
  }

  const categoriesFilter = categoriesStatistics || []

  const totalRelatedPostsAmount = useMemo(
    () =>
      categoriesFilter.reduce(
        (total: number, category: Categories.CategoryStatistics) =>
          total + category.relatedPostsAmount,
        0
      ),
    [categoriesStatistics]
  )

  const categoriesAll = useMemo(
    () => [
      {
        id: '',
        nameVi: 'Tất cả danh mục',
        nameEn: 'All categories',
        nameJa: 'すべてのカテゴリー',
        nameKo: '모든 카테고리',
        relatedPostsAmount: totalRelatedPostsAmount,
      },
    ],
    [totalRelatedPostsAmount]
  )

  const renderListTags = [...categoriesAll, ...categoriesFilter].map((item) => {
    const isActive = filter.categoryId === item.id

    const itemName = item[`name${lang}`]
    const relatedPosts = item.relatedPostsAmount
    const displayText = itemName + ' (' + relatedPosts + ')'

    return (
      <div
        className={classNames('tag-item', { active: isActive })}
        key={item.id}
        onClick={() => setFilter({ ...filter, categoryId: item.id })}
      >
        {displayText}
      </div>
    )
  })

  const onChangePage = (value: number) => {
    fetchData(value)
  }

  return (
    <Layout>
      <PageTitle title={t('Case Study')} />

      <div className="case-study-list">
        <div className="main-container custom-container">
          <div className="filter">
            <div className="box-search">
              <Input
                className="input-search"
                placeholder={t('Search here...')}
                prefix={<img src={Search} width={24} height={24} />}
                onChange={(e) =>
                  setFilter({ ...filter, keyword: e.target.value })
                }
                onPressEnter={() => fetchData()}
              />
              <Button
                className="btn-search min-w-[165px] h-[48px]"
                onClick={() => fetchData()}
              >
                {t('Search')}
              </Button>
            </div>
            <div className="list-tags">{renderListTags}</div>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center">
              <Loading loading={isLoading} />
            </div>
          ) : (
            <>
              <div className="list-card-items">{listItems()}</div>
              {caseStudiesPosts?.total ? (
                <div className="box-pagination">
                  <Pagination
                    total={caseStudiesPosts?.total}
                    onChange={onChangePage}
                    current={currentPage}
                    pageSize={caseStudiesPosts?.limit}
                  />
                </div>
              ) : (
                <div className="no-data"> {t('No data')} </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudyList
