import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/imageGenerator/banner.png'
import ImageFeature1 from '@/assets/img/imageGenerator/feature_1.png'
import ImageFeature2 from '@/assets/img/imageGenerator/feature_2.png'
import ImageFeature3 from '@/assets/img/imageGenerator/feature_3.png'
import ImageFeature4 from '@/assets/img/imageGenerator/feature_4.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'

const ImageGenerator: React.FC = () => {
  const { t } = useTranslation(['common'])

  const dataBanner = {
    titleVi: t('Image Generator'),
    titleEn: t('Image Generator'),
    titleJa: t('Image Generator'),
    titleKo: t('Image Generator'),
    descriptionVi: t('image_generator_description'),
    descriptionEn: t('image_generator_description'),
    descriptionJa: t('image_generator_description'),
    descriptionKo: t('image_generator_description'),
    image: ImageBanner,
  }

  const dataFeature = {
    titleVi: t('Features'),
    titleEn: t('Features'),
    titleJa: t('Features'),
    titleKo: t('Features'),
    items: [
      {
        titleVi: t('Creative flexibility"'),
        titleEn: t('Creative flexibility"'),
        titleJa: t('Creative flexibility"'),
        titleKo: t('Creative flexibility"'),
        descriptionVi: t('image_generator_feature_1'),
        descriptionEn: t('image_generator_feature_1'),
        descriptionJa: t('image_generator_feature_1'),
        descriptionKo: t('image_generator_feature_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('Improved Fine-Tuning Process'),
        titleEn: t('Improved Fine-Tuning Process'),
        titleJa: t('Improved Fine-Tuning Process'),
        titleKo: t('Improved Fine-Tuning Process'),
        descriptionVi: t('image_generator_feature_2'),
        descriptionEn: t('image_generator_feature_2'),
        descriptionJa: t('image_generator_feature_2'),
        descriptionKo: t('image_generator_feature_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('High Scalability'),
        titleEn: t('High Scalability'),
        titleJa: t('High Scalability'),
        titleKo: t('High Scalability'),
        descriptionVi: t('image_generator_feature_3'),
        descriptionEn: t('image_generator_feature_3'),
        descriptionJa: t('image_generator_feature_3'),
        descriptionKo: t('image_generator_feature_3'),
        image: ImageFeature3,
      },
      {
        titleVi: t('Enhanced Capabilities'),
        titleEn: t('Enhanced Capabilities'),
        titleJa: t('Enhanced Capabilities'),
        titleKo: t('Enhanced Capabilities'),
        descriptionVi: t('image_generator_feature_4'),
        descriptionEn: t('image_generator_feature_4'),
        descriptionJa: t('image_generator_feature_4'),
        descriptionKo: t('image_generator_feature_4'),
        image: ImageFeature4,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('FAQ from Image Generator'),
    titleEn: t('FAQ from Image Generator'),
    titleJa: t('FAQ from Image Generator'),
    titleKo: t('FAQ from Image Generator'),
    items: [
      {
        titleVi: t('What is Image Generator?'),
        titleEn: t('What is Image Generator?'),
        titleJa: t('What is Image Generator?'),
        titleKo: t('What is Image Generator?'),
        descriptionVi: t('image_generator_faq_1'),
        descriptionEn: t('image_generator_faq_1'),
        descriptionJa: t('image_generator_faq_1'),
        descriptionKo: t('image_generator_faq_1'),
      },
      {
        titleVi: t('How does Image Generator work?'),
        titleEn: t('How does Image Generator work?'),
        titleJa: t('How does Image Generator work?'),
        titleKo: t('How does Image Generator work?'),
        descriptionVi: t('image_generator_faq_2'),
        descriptionEn: t('image_generator_faq_2'),
        descriptionJa: t('image_generator_faq_2'),
        descriptionKo: t('image_generator_faq_2'),
      },
      {
        titleVi: t(
          'What is the difference between Image Generator and other AI image generators?'
        ),
        titleEn: t(
          'What is the difference between Image Generator and other AI image generators?'
        ),
        titleJa: t(
          'What is the difference between Image Generator and other AI image generators?'
        ),
        titleKo: t(
          'What is the difference between Image Generator and other AI image generators?'
        ),
        descriptionVi: t('image_generator_faq_3'),
        descriptionEn: t('image_generator_faq_3'),
        descriptionJa: t('image_generator_faq_3'),
        descriptionKo: t('image_generator_faq_3'),
      },
      {
        titleVi: t('Can I use Image Generator for commercial purposes?'),
        titleEn: t('Can I use Image Generator for commercial purposes?'),
        titleJa: t('Can I use Image Generator for commercial purposes?'),
        titleKo: t('Can I use Image Generator for commercial purposes?'),
        descriptionVi: t('image_generator_faq_4'),
        descriptionEn: t('image_generator_faq_4'),
        descriptionJa: t('image_generator_faq_4'),
        descriptionKo: t('image_generator_faq_4'),
      },
      {
        titleVi: t(
          'What are some tips for creating effective prompts for Image Generator?'
        ),
        titleEn: t(
          'What are some tips for creating effective prompts for Image Generator?'
        ),
        titleJa: t(
          'What are some tips for creating effective prompts for Image Generator?'
        ),
        titleKo: t(
          'What are some tips for creating effective prompts for Image Generator?'
        ),
        descriptionVi: t('image_generator_faq_5'),
        descriptionEn: t('image_generator_faq_5'),
        descriptionJa: t('image_generator_faq_5'),
        descriptionKo: t('image_generator_faq_5'),
      },
    ],
  }

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="try-demo min-w-[245px] h-[48px]"
            onClick={() =>
              window.open(process.env.REACT_APP_URL_STABLE_DIFFUSION, '_blank')
            }
          >
            {t('Try Demo')}
          </Button>
        }
      />
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
        isBackground
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default ImageGenerator
