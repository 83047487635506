/* eslint-disable @typescript-eslint/no-inferrable-types */

import { UploadFile } from 'antd'

import { TYPE_FILE_IMAGE } from '@/contants/upload'

export const base64ToFile = (
  base64String: string,
  fileName: string,
  mimeType: string
): File => {
  // Strip off the base64 header if present
  const byteString = atob(base64String.split(',')[1])

  // Create an array to hold the binary data
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uint8Array = new Uint8Array(arrayBuffer)

  // Convert the binary string into the Uint8Array
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i)
  }

  // Create a File object from the binary data
  return new File([uint8Array], fileName, { type: mimeType })
}

export function canvasToFile(
  canvas: HTMLCanvasElement,
  fileName: string,
  fileType: string = 'image/png'
): Promise<File> {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileName, { type: fileType })
        resolve(file)
      } else {
        reject(new Error('Canvas toBlob failed.'))
      }
    }, fileType)
  })
}

export function convertPdfToUrl(file: UploadFile): Promise<string> {
  return new Promise((resolve, reject) => {
    if (file.type !== 'application/pdf') {
      reject(new Error('The file is not in PDF format'))
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string)
      } else {
        reject(new Error('Cannot read PDF file'))
      }
    }

    reader.onerror = () =>
      reject(new Error('An error occurred while reading the PDF file'))

    reader.readAsDataURL(file as any)
  })
}

export function convertImageToUrl(file: UploadFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const isImage = TYPE_FILE_IMAGE.includes(file.type || '')

    if (!isImage) {
      reject(new Error('File is not an image'))
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string)
      } else {
        reject(new Error('Cannot read image file'))
      }
    }

    reader.onerror = () =>
      reject(new Error('An error occurred while reading the image file'))

    reader.readAsDataURL(file as any)
  })
}

export default convertImageToUrl
