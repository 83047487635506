import './style.scss'
import 'suneditor/dist/css/suneditor.min.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '@/components/Common/Item/Button'
import SlickSlider from '@/components/Common/Item/Slider'
import { getCaseStudiesPostsAction } from '@/states/features/caseStudies/caseStudies.action'
import actions from '@/states/features/caseStudies/caseStudies.reducer'
import { useAppDispatch } from '@/states/store'
import { capitalizeFirstLetter } from '@/utils/common'
const { resetCaseStudies } = actions

interface Props {
  categoryId: string | 'all'
}

const CardSlider: React.FC<Props> = ({ categoryId }) => {
  const { i18n, t } = useTranslation(['common'])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [caseStudy, setCaseStudy] = useState<CaseStudies.CaseStudiesCardItem>()
  const lang = capitalizeFirstLetter(i18n.language)

  useEffect(() => {
    if (categoryId) {
      fetchData()
    }

    return () => {
      resetCaseStudies()
    }
  }, [categoryId])

  const fetchData = async () => {
    const response = await dispatch(
      getCaseStudiesPostsAction({
        params: {
          limit: 5,
          categoryId: categoryId === 'all' ? undefined : categoryId,
        },
      })
    )
    const result = response?.payload?.data?.records

    if (result) {
      setCaseStudy(result)
    }
  }

  return Array.isArray(caseStudy) && caseStudy?.length ? (
    <section className="mvp_building top" id="mvp_building">
      <div className="main-container custom-container">
        <SlickSlider
          isActions
          settings={{
            dots: true,
            infinite: caseStudy.length > 1,
            speed: 500,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          }}
        >
          {Array.isArray(caseStudy) &&
            caseStudy.map((caseStudy) => {
              return (
                <div key={caseStudy.id} className="card-slide-item">
                  <div className="project-item">
                    <div>
                      <div className="building-image">
                        <img src={caseStudy.thumbnail} alt="Metaverse" />
                      </div>
                      <div className="building-content">
                        <h2 className="title">
                          {t(caseStudy[`title${lang}`])}
                        </h2>
                        <div
                          className="wrapper-content-editor sun-editor-editable"
                          dangerouslySetInnerHTML={{
                            __html: caseStudy[`content${lang}`] || '',
                          }}
                        />
                        <div className="box-actions">
                          <Button
                            className="read-more w-[160px] h-[48px]"
                            onClick={() =>
                              navigate(`/case-study/${caseStudy.id}`)
                            }
                          >
                            {t('Read more')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </SlickSlider>
        {/* <div className="box-actions">
          <Button
            className="read-more w-[160px] h-[48px]"
            onClick={() => navigate('/case-study')}
          >
            {t('See all')}
          </Button>
        </div> */}
      </div>
    </section>
  ) : (
    <> </>
  )
}

export default CardSlider
