import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/nftMarketplace/banner.png'
import ImageFeature1 from '@/assets/img/nftMarketplace/feature_1.png'
import ImageFeature2 from '@/assets/img/nftMarketplace/feature_2.png'
import ImageFeature3 from '@/assets/img/nftMarketplace/feature_3.png'
import ImageFeature4 from '@/assets/img/nftMarketplace/feature_4.png'
import ImageService1 from '@/assets/img/nftMarketplace/service_1.png'
import ImageService2 from '@/assets/img/nftMarketplace/service_2.png'
import ImageService3 from '@/assets/img/nftMarketplace/service_3.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'
import Services from '@/templates/PRServices'

const AIOcr: React.FC = () => {
  const { t } = useTranslation(['common'])

  const dataBanner = {
    titleVi: t('NFT Marketplace'),
    titleEn: t('NFT Marketplace'),
    titleJa: t('NFT Marketplace'),
    titleKo: t('NFT Marketplace'),
    descriptionVi: t('description_nft_marketplace'),
    descriptionEn: t('description_nft_marketplace'),
    descriptionJa: t('description_nft_marketplace'),
    descriptionKo: t('description_nft_marketplace'),
    image: ImageBanner,
  }

  const dataServices = {
    titleVi: t('Our Services'),
    titleEn: t('Our Services'),
    titleJa: t('Our Services'),
    titleKo: t('Our Services'),
    items: [
      {
        titleVi: t('Buy Token'),
        titleEn: t('Buy Token'),
        titleJa: t('Buy Token'),
        titleKo: t('Buy Token'),
        descriptionVi: t('nft_marketplace_service_1'),
        descriptionEn: t('nft_marketplace_service_1'),
        descriptionJa: t('nft_marketplace_service_1'),
        descriptionKo: t('nft_marketplace_service_1'),
        image: ImageService1,
      },
      {
        titleVi: t('Airdrop NFT'),
        titleEn: t('Airdrop NFT'),
        titleJa: t('Airdrop NFT'),
        titleKo: t('Airdrop NFT'),
        descriptionVi: t('nft_marketplace_service_2'),
        descriptionEn: t('nft_marketplace_service_2'),
        descriptionJa: t('nft_marketplace_service_2'),
        descriptionKo: t('nft_marketplace_service_2'),
        image: ImageService2,
      },
      {
        titleVi: t('Swap NFT'),
        titleEn: t('Swap NFT'),
        titleJa: t('Swap NFT'),
        titleKo: t('Swap NFT'),
        descriptionVi: t('nft_marketplace_service_3'),
        descriptionEn: t('nft_marketplace_service_3'),
        descriptionJa: t('nft_marketplace_service_3'),
        descriptionKo: t('nft_marketplace_service_3'),
        image: ImageService3,
      },
    ],
  }

  const dataFeature = {
    titleVi: t('Key Features'),
    titleEn: t('Key Features'),
    titleJa: t('Key Features'),
    titleKo: t('Key Features'),
    items: [
      {
        titleVi: t('NFT Creation (Minting)'),
        titleEn: t('NFT Creation (Minting)'),
        titleJa: t('NFT Creation (Minting)'),
        titleKo: t('NFT Creation (Minting)'),
        descriptionVi: t('nft_marketplace_feature_1'),
        descriptionEn: t('nft_marketplace_feature_1'),
        descriptionJa: t('nft_marketplace_feature_1'),
        descriptionKo: t('nft_marketplace_feature_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('Marketplace for Buying and Selling NFTs'),
        titleEn: t('Marketplace for Buying and Selling NFTs'),
        titleJa: t('Marketplace for Buying and Selling NFTs'),
        titleKo: t('Marketplace for Buying and Selling NFTs'),
        descriptionVi: t('nft_marketplace_feature_2'),
        descriptionEn: t('nft_marketplace_feature_2'),
        descriptionJa: t('nft_marketplace_feature_2'),
        descriptionKo: t('nft_marketplace_feature_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('Wallet Integration'),
        titleEn: t('Wallet Integration'),
        titleJa: t('Wallet Integration'),
        titleKo: t('Wallet Integration'),
        descriptionVi: t('nft_marketplace_feature_3'),
        descriptionEn: t('nft_marketplace_feature_3'),
        descriptionJa: t('nft_marketplace_feature_3'),
        descriptionKo: t('nft_marketplace_feature_3'),
        image: ImageFeature3,
      },
      {
        titleVi: t('Royalties & Resale Features'),
        titleEn: t('Royalties & Resale Features'),
        titleJa: t('Royalties & Resale Features'),
        titleKo: t('Royalties & Resale Features'),
        descriptionVi: t('nft_marketplace_feature_4'),
        descriptionEn: t('nft_marketplace_feature_4'),
        descriptionJa: t('nft_marketplace_feature_4'),
        descriptionKo: t('nft_marketplace_feature_4'),
        image: ImageFeature4,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('FAQ from Marketplace'),
    titleEn: t('FAQ from Marketplace'),
    titleJa: t('FAQ from Marketplace'),
    titleKo: t('FAQ from Marketplace'),
    items: [
      {
        titleVi: t('What are NFTs?'),
        titleEn: t('What are NFTs?'),
        titleJa: t('What are NFTs?'),
        titleKo: t('What are NFTs?'),
        descriptionVi: t('nft_marketplace_faq_1'),
        descriptionEn: t('nft_marketplace_faq_1'),
        descriptionJa: t('nft_marketplace_faq_1'),
        descriptionKo: t('nft_marketplace_faq_1'),
      },
      {
        titleVi: t('What is a gas bill?'),
        titleEn: t('What is a gas bill?'),
        titleJa: t('What is a gas bill?'),
        titleKo: t('What is a gas bill?'),
        descriptionVi: t('nft_marketplace_faq_2'),
        descriptionEn: t('nft_marketplace_faq_2'),
        descriptionJa: t('nft_marketplace_faq_2'),
        descriptionKo: t('nft_marketplace_faq_2'),
      },
      {
        titleVi: t('Forms of buying and selling in the market?'),
        titleEn: t('Forms of buying and selling in the market?'),
        titleJa: t('Forms of buying and selling in the market?'),
        titleKo: t('Forms of buying and selling in the market?'),
        descriptionVi: t('nft_marketplace_faq_3'),
        descriptionEn: t('nft_marketplace_faq_3'),
        descriptionJa: t('nft_marketplace_faq_3'),
        descriptionKo: t('nft_marketplace_faq_3'),
      },
      {
        titleVi: t('What currencies can I use at NFT Marketplace?'),
        titleEn: t('What currencies can I use at NFT Marketplace?'),
        titleJa: t('What currencies can I use at NFT Marketplace?'),
        titleKo: t('What currencies can I use at NFT Marketplace?'),
        descriptionVi: t('nft_marketplace_faq_4'),
        descriptionEn: t('nft_marketplace_faq_4'),
        descriptionJa: t('nft_marketplace_faq_4'),
        descriptionKo: t('nft_marketplace_faq_4'),
      },
    ],
  }

  const dataServiceItems = dataServices.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="try-demo min-w-[245px] h-[48px]"
            onClick={() =>
              window.open(process.env.REACT_APP_URL_NFT_MARKETPLACE, '_blank')
            }
          >
            {t('Try Demo')}
          </Button>
        }
      />
      <Services
        title={useGetTextLanguage('title', dataServices)}
        data={dataServiceItems}
      />
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default AIOcr
