import APIClientFactory from './APIClientFactory'

export const postIDCardFile = async (file, docType) => {
  const apiAxios = APIClientFactory.get('rest')
  // eslint-disable-next-line no-undef
  const formData = new FormData()
  formData.append('file', file)
  formData.append('docType', docType)
  return await apiAxios.post('api/orc/ekyc', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getIDCardDetail = async (requestId) => {
  const apiAxios = APIClientFactory.get('rest')
  return await apiAxios.get(`api/orc/ekyc/${requestId}`)
}
