import './style.scss'

import classNames from 'classnames'
import React from 'react'

interface PageTitleProps {
  title?: string;
  className?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  className,
  title,
  ...props
}) => {
  return (
    <div {...props} className={classNames('page-title-bg', className)}>
      <h2 className="title">{title}</h2>
    </div>
  )
}

export default PageTitle
