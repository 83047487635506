import './style.scss'

import React, { ReactElement } from 'react'

type PRBannerProps = {
  title?: string
  description?: string
  image?: string
  button?: ReactElement
  children?: ReactElement
}

const PRBanner: React.FC<PRBannerProps> = ({
  title,
  description,
  image,
  button,
  children,
}) => {
  return (
    <section id="banner">
      <div className="pr-banner">
        <div className="content-container">
          <div className="content">
            {title && <p className="title">{title}</p>}
            {description && <p className="description">{description}</p>}

            {button}
          </div>

          {image && (
            <div
              className="bg-contain bg-no-repeat w-[625px] h-[494px]"
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          )}
        </div>
        {children}
      </div>
    </section>
  )
}

export default PRBanner
