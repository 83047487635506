import './style.scss'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
import Spinner from '@/components/Common/Item/Spinner'
import { getInfoAuthenticateAction } from '@/states/features/thirdParty/thirdParty.action'
import { useAppDispatch, useAppSelector } from '@/states/store'

const Banner: React.FC = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.thirdParty)

  const [sessionId, setSessionId] = useState('')

  useEffect(() => {
    if (sessionId) {
      window.open(
        `${process.env.REACT_APP_URL_DIGITAL_TRANSFORMATION}/auto-login?session_id=${sessionId}`,
        '_blank'
      )
    }
  }, [sessionId])

  const handleTryDemo = async () => {
    await dispatch(getInfoAuthenticateAction({})).then((result: any) => {
      const sessionId = result?.payload?.data?.sessionCookie?.sessionId

      setSessionId(sessionId)
    })
  }

  return (
    <section id="banner">
      <Spinner loading={isLoading} />
      <div className="banner digital-transformation">
        <div className="content-container">
          <div className="content">
            <p className="title">
              {t('Accelerate Your Business with Digital Transformation')}
            </p>
            <div className="child-content">
              <p>{t('digital_transformation_description_banner')}</p>
            </div>
            <Button
              className="explore_more w-[160px] h-[48px]"
              disabled={isLoading}
              onClick={handleTryDemo}
            >
              {t('Try Demo')}
            </Button>
          </div>
          <div className="bg-digital_transformation bg-contain bg-no-repeat w-[500px] h-[350px]"></div>
        </div>
      </div>
    </section>
  )
}

export default Banner
