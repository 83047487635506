import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageIntroduce from '@/assets/img/digitalTransformation/introduce.png'
import ImageIntroduce1 from '@/assets/img/digitalTransformation/introduce_1.png'
import ImageIntroduce2 from '@/assets/img/digitalTransformation/introduce_2.png'
import ImageIntroduce3 from '@/assets/img/digitalTransformation/introduce_3.png'
import CardFeature from '@/components/Common/Item/CardFeature'

const Introduce = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      image: ImageIntroduce1,
      title: t('introduce_title_1'),
      description: t('introduce_description_1'),
    },
    {
      image: ImageIntroduce2,
      title: t('introduce_title_2'),
      description: t('introduce_description_2'),
    },
    {
      image: ImageIntroduce3,
      title: t('introduce_title_3'),
      description: t('introduce_description_3'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <CardFeature
        key={index}
        image={item.image}
        title={item.title}
        description={item.description}
      />
    )
  })

  return (
    <section className="introduce" id="introduce">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">{t('What is Digital Transformation?')}</h2>
          <p className="description">{t('introduce_description')}</p>
          <div className="image-intro">
            <img src={ImageIntroduce} alt="What is Digital Transformation?" />
          </div>
        </div>

        <div className="list-key-areas">
          <h3 className="title">
            {t('Key Areas of Enterprise Digital Transformation')}
          </h3>
          <p className="description">
            {t(
              'The Foundation for Sustainable Growth and Continuous Innovation'
            )}
          </p>

          <div className="list-cards">{renderListItems}</div>
        </div>
      </div>
    </section>
  )
}

export default Introduce
