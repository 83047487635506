import './style.scss'

import { Col, message, Row } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { useTranslation } from 'react-i18next'

import ImageMic from '@/assets/img/mic.png'
import ImageMicPause from '@/assets/img/mic_pause.png'
import ImageSound from '@/assets/img/sound.png'
import IconUploadFile from '@/assets/svg/upload-file.svg'
import Button from '@/components/Common/Item/Button'
import Loading from '@/components/Common/Item/Loading'
import CustomTextArea from '@/components/Common/Item/TextArea'
import { AUDIO_MAX_RECORD_TIME } from '@/contants'
import { TYPE_FILE_RADIO } from '@/contants/upload'
import { postAudioFileAction } from '@/states/features/speechToText/speechTotext.action'
import actions from '@/states/features/speechToText/speechToText.reducer'
import { useAppDispatch, useAppSelector } from '@/states/store'
import { handleDownload } from '@/utils/download'

const { resetFileToText, setFileToText } = actions

const Audio: React.FC = () => {
  const { t } = useTranslation(['common'])
  const [toggleMic, setToggleMic] = useState(false)
  const [timeRecord, setTimeRecord] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState<string>('')

  const dispatch = useAppDispatch()
  const { audioFileToText, isLoading } = useAppSelector(
    (state) => state.speechToText
  )

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.error(err)
  )

  const onResetDemo = () => {
    dispatch(resetFileToText())
  }

  useEffect(() => {
    return () => {
      onResetDemo()
      setFileName('')
    }
  }, [])

  const addAudioElement = (blob: Blob) => {
    setFileName('')
    const url = URL.createObjectURL(blob)
    const audio = document.createElement('audio')
    audio.src = url
    audio.controls = true
    document.body.appendChild(audio)
    handleUploadFileRecord(blob)
  }

  useEffect(() => {
    if (timeRecord > AUDIO_MAX_RECORD_TIME) {
      stopRecording()
      message.warning(
        t('Recording time exceeds allowed limit', { ns: 'validation' })
      )
    }
  }, [timeRecord])

  const startRecording = async () => {
    try {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }

      await navigator.mediaDevices.getUserMedia({ audio: true })
      recorderControls.startRecording()
      setToggleMic(true)
      setTimeRecord(0)

      intervalRef.current = setInterval(() => {
        setTimeRecord((prevTime) => {
          return prevTime + 1
        })
      }, 1000)
    } catch (err) {
      console.error('Microphone access denied:', err)
    }
  }

  const stopRecording = async () => {
    recorderControls.stopRecording()
    setToggleMic(false)

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const handleUploadFileRecord = async (blob: Blob) => {
    if (!recorderControls.recordingBlob) return
    await dispatch(postAudioFileAction({ params: { file: blob } }))
    setFileName(blob.type)
  }

  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600)
    const mins = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFileName('')

    if (event?.target?.files && event?.target?.files?.length > 0) {
      const file: any = event.target.files[0]

      const fileExtension = file.name.split('.').pop()?.toLowerCase()

      if (!fileExtension || !TYPE_FILE_RADIO.includes(fileExtension)) {
        message.error(
          t('Please upload a valid audio file', { ns: 'validation' })
        )

        if (inputFileRef.current) {
          inputFileRef.current.value = ''
        }

        return
      }

      const maxSizeInMB = 5
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024

      if (file.size > maxSizeInBytes) {
        message.error(
          t('The file size exceeds the 5MB limit', { ns: 'validation' })
        )

        if (inputFileRef.current) {
          inputFileRef.current.value = ''
        }

        return
      }

      await dispatch(postAudioFileAction({ params: { file: file } }))
      setFileName(file?.name)

      if (inputFileRef.current) {
        inputFileRef.current.value = ''
      }
    }
  }

  const acceptFileTypes = TYPE_FILE_RADIO.map((type) => `audio/${type}`).join(
    ','
  )

  return (
    <section id="audio">
      <div className="main-container custom-container" id="demo-speech2text">
        <h1 className="text-[46px] text-center font-bold">
          {t('Speech to text platform')}
        </h1>
        <p className="text-center leading-6 mt-[18px] mb-[90px]">
          {t('To get started, record or upload your file')}
        </p>

        <div className="audio-speech2text">
          <Row gutter={[16, 16]} className="record-body">
            <Col xs={24} sm={12} md={12} className="wrapper-input">
              <h3 className="title">{t('Record from browser')}</h3>
              <p className="description">
                {t('Click the microphone icon and speak')}
              </p>

              <div
                className={`record-images ${isLoading && 'disabled'}`}
                onClick={() => {
                  if (!isLoading) {
                    toggleMic ? stopRecording() : startRecording()
                  }
                }}
              >
                <img src={ImageSound} className="img-sound" alt="sound" />
                <img
                  src={toggleMic ? ImageMicPause : ImageMic}
                  className="img-mic"
                  alt="mic"
                />
              </div>
              <p className="time-record">{formatTime(timeRecord)}</p>
              <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                downloadOnSavePress={false}
                downloadFileExtension="mp3"
                showVisualizer={true}
              />
              <br />
              <div className="box-actions">
                <hr />
                <p className="note-upload">
                  {t('Or upload an existing audio file')}
                </p>

                <Button
                  radius
                  className="w-[100%] h-[40px] btn-upload"
                  onClick={() => {
                    if (recorderControls.isRecording || isLoading) return
                    inputFileRef.current?.click()
                  }}
                  disabled={recorderControls.isRecording || isLoading}
                >
                  <input
                    ref={inputFileRef}
                    accept={acceptFileTypes}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <img
                    src={IconUploadFile}
                    alt="upload file"
                    width={24}
                    height={24}
                  />
                  <span>{t('Upload your file')}</span>
                </Button>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} className="result-container">
              <div className="wrapper-result">
                <div className="result-box">
                  {isLoading && (
                    <div className="loading">
                      <div className="loading-box">
                        <p className="loading-title">{t('Processing...')}</p>
                        <p className="loading-description">
                          {t('Please wait while the data is being converted')}
                        </p>
                        <Loading loading={isLoading} />
                      </div>
                    </div>
                  )}
                  {!isLoading &&
                    (audioFileToText || !!fileName) &&
                    (audioFileToText.length === 0 ? (
                      <div className="text-note">
                        {t(
                          'No text detected from the audio. Please try again.'
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="text-result">
                          <div className="result-content">
                            <CustomTextArea
                              className="input-result"
                              // autoSize
                              onChange={(e) =>
                                dispatch(setFileToText(e.target.value))
                              }
                              value={audioFileToText}
                            />
                          </div>
                        </div>
                        <div className="result-action-box">
                          <Button
                            className="explore_more min-w-[124px] h-[36px]"
                            radius
                            onClick={() =>
                              handleDownload(audioFileToText, fileName)
                            }
                          >
                            {t('Download')}
                          </Button>
                        </div>
                      </>
                    ))}
                  {!isLoading && !audioFileToText && !fileName && (
                    <div className="text-note">
                      {t('result_speech_to_text_tool')}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
}

export default Audio
