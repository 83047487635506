import { UploadFile } from 'antd'
import classNames from 'classnames'
// import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

import IconSuccessFilled from '@/assets/img/Circle_Check_filled.png'
// import IconFailFilled from '@/assets/img/Circle_Error_filled.png'
import Input from '@/components/Common/Item/Input'
import Loading from '@/components/Common/Item/Loading'
// import { DATE_FORMAT } from '@/contants/date-time'
import { FEATURE_TABS } from '@/contants/face-recognition'
import { GENDER_OPTIONS } from '@/contants/form'

import DetectFaces from '../DetectFaces'

type FaceRecognitionProps = {
  currentTab: string
  loading: boolean
  loadingFace: boolean
  file: UploadFile | File | null
  faces: HTMLCanvasElement[]
  faceSelected: number
  listInfo: FaceRecognition.Persons[]
  isLoading: boolean
  facesExtracted: boolean
  setFaces: (value: HTMLCanvasElement[]) => void
  setFaceSelected: (value: number) => void
  setFacesExtracted: (value: boolean) => void
  setIsVisibleConfirm: (value: boolean) => void
  setDetectAllFacesComplete: (value: boolean) => void
}

const FaceRecognition: React.FC<FaceRecognitionProps> = ({
  currentTab,
  loading,
  loadingFace,
  file,
  faces,
  faceSelected,
  listInfo,
  isLoading,
  facesExtracted,
  setFaces,
  setFaceSelected,
  setFacesExtracted,
  setIsVisibleConfirm,
  setDetectAllFacesComplete,
}) => {
  const { t } = useTranslation(['common', 'validation', 'errors'])

  const handleFaceSelected = (index: number) => {
    setFaceSelected(index)
  }

  const getGender = (value: string) => {
    const options = GENDER_OPTIONS(t)
    const valueOption = options.find((x) => x.value === value)

    return valueOption?.label ? valueOption.label : ''
  }

  return (
    <div
      className={classNames('wrapper-image-info face-recognition', {
        hidden: loading || currentTab === FEATURE_TABS.ADD_NEW,
      })}
    >
      <DetectFaces
        file={file}
        loadingFace={loadingFace}
        faceSelected={faceSelected}
        faces={faces}
        listInfo={listInfo}
        facesExtracted={facesExtracted}
        setFaces={setFaces}
        setIsVisibleConfirm={setIsVisibleConfirm}
        setFacesExtracted={setFacesExtracted}
        handleFaceSelected={handleFaceSelected}
        setDetectAllFacesComplete={setDetectAllFacesComplete}
      />

      <div className="line" />

      <div className="info-box">
        {faces.length > 0 && !loading ? (
          isLoading ? (
            <div className="wrapper-request-loading">
              <p className="loading-title">{t('Processing...')}</p>
              <p className="loading-description">
                {t('Processing your data, please hold on..')}
              </p>
              <Loading loading={isLoading} />
            </div>
          ) : (
            <form>
              {/* <div className="form-info-header">
                <p className="form-title">{t('Results')}</p>

                {listInfo[faceSelected]?.error ? (
                  <div className="status-verified">
                    <img src={IconFailFilled} alt="Status verified" />
                    <span className="text fail">{t('Failed')}</span>
                  </div>
                ) : (
                  <div className="status-verified">
                    <img src={IconSuccessFilled} alt="Status verified" />
                    <span className="text">{t('Verified')}</span>
                  </div>
                )}
              </div> */}

              {!listInfo[faceSelected]?.error && (
                <div className="form-info-header">
                  <p className="form-title">{t('Results')}</p>
                  <div className="status-verified">
                    <img src={IconSuccessFilled} alt="Status verified" />
                    <span className="text">{t('Verified')}</span>
                  </div>
                </div>
              )}

              {listInfo[faceSelected]?.error ? (
                <div className="wrapper-error">
                  <p className="massage-error">
                    {t(`${listInfo[faceSelected]?.error}`, {
                      ns: 'errors',
                    })}
                  </p>
                </div>
              ) : (
                <div className="form-body">
                  <div className="field-input">
                    <Input
                      result
                      inline
                      required
                      label={t('Full name')}
                      placeholder={t('Enter Full name')}
                      name="fullName"
                      type="text"
                      value={listInfo[faceSelected]?.fullName}
                    />
                  </div>
                  {/* <div className="field-input">
                    <Input
                      result
                      readOnly
                      inline
                      required
                      label={t('Country')}
                      placeholder={t('Enter Country')}
                      name="country"
                      type="text"
                      value={listInfo[faceSelected]?.country}
                    />
                  </div> */}
                  {/* <div className="field-input">
                    <Input
                      result
                      readOnly
                      inline
                      label={t('Birthday')}
                      name="birthday"
                      type="text"
                      value={
                        listInfo[faceSelected]?.birthday
                          ? dayjs(listInfo[faceSelected]?.birthday).format(
                              DATE_FORMAT
                            )
                          : ''
                      }
                    />
                  </div> */}
                  <div className="field-input">
                    <Input
                      result
                      readOnly
                      inline
                      label={t('Gender')}
                      name="gender"
                      type="text"
                      value={getGender(listInfo[faceSelected]?.gender)}
                    />
                  </div>
                  {/* <div className="field-input">
                    <Input
                      result
                      inline
                      required
                      label={t('Age')}
                      placeholder={t('Enter Age')}
                      name="age"
                      type="text"
                      value={listInfo[faceSelected]?.age}
                    />
                  </div> */}
                  {/* <div className="field-input">
                    <Input
                      result
                      inline
                      required
                      label={t('Email')}
                      placeholder={t('Enter Email')}
                      name="email"
                      type="text"
                      value={listInfo[faceSelected]?.email}
                    />
                  </div> */}
                  {/* <div className="field-input">
                    <Input
                      result
                      inline
                      required
                      label={t('Phone number')}
                      placeholder={t('Enter Phone number')}
                      name="phoneNumber"
                      type="text"
                      value={listInfo[faceSelected]?.phoneNumber}
                    />
                  </div> */}
                </div>
              )}
            </form>
          )
        ) : (
          <div
            className={classNames('wrapper-error', {
              hidden: loading,
            })}
          >
            <p className="massage-error">{t('Face not detected')}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default FaceRecognition
