import { createSlice } from '@reduxjs/toolkit'

import { ocrFileToTextAction } from './ocr.action'

const initialState: OCR.OCRState = {
  isLoading: false,
  text: '',
  translates: null,
  isError: false,
  isConvertSuccess: false,
  errorContact: null,
}

export const { actions, reducer } = createSlice({
  name: 'OCR',
  initialState,
  reducers: {
    resetErrorContact: (state) => {
      state.errorContact = null
    },
    resetFileToText: (state) => {
      state.text = ''
      state.isConvertSuccess = false
    },
    setFileToText: (state, action) => {
      state.text = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ocrFileToTextAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(ocrFileToTextAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isConvertSuccess = true
        state.text = action.payload.data.text
        state.translates = action.payload.data.translates
        state.isError = action.payload.data.isError
      })
      .addCase(ocrFileToTextAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorContact = action.payload
      })
  },
})

export default actions
