import React, { useEffect, useState } from 'react'

import bannerWhyAIImg from '../../../../../../assets/img/orc/orc_video_thumb.png'
import {
  getIDCardDetail,
  postIDCardFile,
} from '../../../../../../services/api/orc.ai'
import BtnUpload from './BtnUpload'
import DocumentTypeSelectBox, {
  DOCUMENT_TYPE_ID,
} from './DocumentTypeSelectBox'
import TableIDCard, { IDCardInfo } from './TableIDCard'

// eslint-disable-next-line no-unused-vars
// const defaultInfo: IDCardInfo = {
//   full_name: 'DAVIS SIPHRON',
//   id_card_number: '0123456789',
//   birthday: '12/05/1990',
//   nationality: 'VIET NAM',
//   hometown: '2352, P1, Q1, TP HO CHI MINH',
//   address: '123/12/12 BINH THANH, P1, 10, TP HO CHI MINH',
//   place_of_issue: '123/12/12 BINH THANH, P1, 10, TP HO CHI MINH',
// }

const OCRTryItOut: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState(DOCUMENT_TYPE_ID.ID)
  // eslint-disable-next-line no-unused-vars
  // const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [imageUrl, setImageURL] = useState<string | null>(null)

  const [requestId, setRequestId] = useState<string | null>(null)
  const [result, setResult] = useState<IDCardInfo | null>(null)
  const [status, setStatus] = useState<string | null>()

  const handleOptionChange = (value: string) => {
    setSelectedOption(value)
  }

  const handleOnSelectFile = async (file: File) => {
    try {
      // setSelectedFile(file)
      const imageUrl = URL.createObjectURL(file)
      setImageURL(imageUrl)

      const response = await postIDCardFile(file, selectedOption)

      if (response && response.data) {
        setStatus(response.data.status)
        setRequestId(response.data._id)
      }
    } catch (err) {
      if (err instanceof Error) {
        setStatus(err.message)
      } else {
        setStatus('An unknown error occurred')
      }
    }
  }

  useEffect(() => {
    if (!requestId) return
    const interval = setInterval(() => {
      console.log('pull request start', requestId)
      getIDCardDetail(requestId)
        .then((response) => {
          if (
            response.data.status === 'completed' ||
            response.data.status === 'failed'
          ) {
            clearInterval(interval)
          }

          if (response.data.status === 'completed') {
            let resultResponse = response.data.result

            try {
              resultResponse = resultResponse && JSON.parse(resultResponse)
            } catch (_) {
              console.log('getIDCardDetail JSON.parse err')
            }

            setResult(resultResponse)
          }

          setStatus(response.data.status)
        })
        .catch((err) => {
          console.log(err)
        })
    }, 1000)
    return () => clearInterval(interval)
  }, [requestId])

  return (
    <div className="relative flex flex-col justify-start items-start gap-4 bg-white max-w-[560px] rounded-[20px] py-8 px-6 md:py-[40px] md:px-[32px] w-full mb-8 md:mb-0 p-8">
      <DocumentTypeSelectBox
        onSelected={handleOptionChange}
        selectedOption={selectedOption}
      />
      <BtnUpload onSelectFile={handleOnSelectFile} />
      <p className="w-full text-xs text-gray-400">
        {`Computation status: ${status || 'cached'}`}
      </p>
      <p>Result</p>
      <div className="flex justify-between items-start gap-x-4 h-[332px]">
        <img
          src={imageUrl || bannerWhyAIImg}
          width={168}
          height={211}
          className="object-contain"
        ></img>
        <TableIDCard data={result} />
      </div>
    </div>
  )
}

export default OCRTryItOut
