import './style.scss'

import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
import AboutUs from '@/components/Common/Pages/AboutUs'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'

const Bannervideo: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()

  return (
    <section id="banner">
      <div className="banner-home">
        {/* <div className="absolute z-0 top-0 left-0 w-full h-[520px]">
          <div className="absolute w-full h-full bottom-0 left-0 bg-banner_overlay bg-cover bg-fixed bg-opacity-[0.7]"></div>
          <div className="absolute w-full h-full top-0 left-0 bg-banner_texture bg-cover bg-opacity-[0.4]"></div>
          <div className="absolute w-full h-full bottom-0 left-0 bg-technology_pattern bg-cover"></div>
          <img></img>
          <div className="absolute bottom-0 left-0 bg-mask_ellipse_1 bg-contain bg-no-repeat w-[485px] h-[485px]"></div>
          <div className="absolute top-[64px] right-[120px] bg-ai_bot bg-contain bg-no-repeat w-[330px] h-[386px]"></div>
        </div> */}

        <div className="content-container">
          <div className="content">
            <p className="title">{t('Empowering Tomorrow')}</p>
            <div className="child-content">
              <p>{t('description_1')}</p>
              <p>{t('description_2')}</p>
            </div>
            <Button
              className="explore_more w-[160px] h-[48px]"
              onClick={() => scrollToSection('contact')}
            >
              {t('Contact')}
            </Button>
          </div>
          <div className="bg-ai_bot bg-contain bg-no-repeat min-w-[478px] h-[644px] max-w-100"></div>
        </div>

        <AboutUs />
      </div>
    </section>
  )
}

export default Bannervideo
