import './style.scss'

import classNames from 'classnames'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ImageHand from '@/assets/img/hand.png'
import Button from '@/components/Common/Item/Button'
import Input from '@/components/Common/Item/Input'
import TextArea from '@/components/Common/Item/TextArea'
import { sendContactAction } from '@/states/features/contact/contact.action'
import { useAppDispatch, useAppSelector } from '@/states/store'

import { contactFormSchema } from './ContactFormSchema'

const Contact = () => {
  const { t, i18n } = useTranslation(['common', 'validation'])
  const dispatch = useAppDispatch()

  const { isLoading } = useAppSelector((state) => state.contact)

  useEffect(() => {
    formik.validateForm()
  }, [i18n.language])

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      companyName: '',
      phoneNumber: '',
      message: '',
    },
    validationSchema: contactFormSchema(t),
    onSubmit: async (values) => {
      const params = {
        name: values.name.trim(),
        email: values.email.trim(),
        companyName: values.companyName.trim(),
        phoneNumber: values.phoneNumber.trim(),
        message: values.message.trim(),
      }
      await dispatch(sendContactAction({ params }))
    },
  })

  return (
    <section className="contact" id="contact">
      <div className="main-container custom-container">
        <div className="main-image">
          <img src={ImageHand} alt="hand-sub" />
        </div>
        <div className="wrapper-contact">
          <h2 className="title">{t('Contact')}</h2>
          <h3 className="sub-title">{t('contact_subtitle')}</h3>
          <p className="description">{t('contact_description')}</p>

          <div className="contact-form-main">
            <form onSubmit={formik.handleSubmit}>
              <div className="field-group">
                <div className="ip-part">
                  <Input
                    required
                    label={t('Full Name')}
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : null
                    }
                  />
                </div>
                <div className="ip-part">
                  <Input
                    required
                    label={t('Email')}
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                  />
                </div>
              </div>
              <div className="field-group">
                <div className="ip-part">
                  <Input
                    required
                    label={t('Company')}
                    name="companyName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    error={
                      formik.touched.companyName && formik.errors.companyName
                        ? formik.errors.companyName
                        : null
                    }
                  />
                </div>
                <div className="ip-part">
                  <Input
                    required
                    label={t('Phone Number')}
                    name="phoneNumber"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber}
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                  />
                </div>
              </div>
              <div className="ip-full">
                <TextArea
                  required
                  label={t('Message')}
                  name="message"
                  rows={5}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  error={
                    formik.touched.message && formik.errors.message
                      ? formik.errors.message
                      : null
                  }
                />
              </div>
              <div className="box-actions">
                <Button
                  className={classNames(
                    'btn-loading mt-[26px] w-240px h-[60px]',
                    { load: isLoading }
                  )}
                  htmlType="submit"
                  disabled={isLoading}
                >
                  <>
                    <span className="span-text">{t('Send')}</span>
                    <span className="loading-spinner"></span>
                  </>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
