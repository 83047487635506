// eslint-disable-next-line simple-import-sort/imports
import { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// import { MainLayout } from '@/components/Common/Layout'
import Blog from '@/components/Common/Pages/Blog'
import Top from '@/components/Common/Pages/Top'
import BlogDetail from '@/components/Common/Pages/BlogDetail'
import ProjectDetail from '@/components/Common/Pages/ProjectDetail'
import WhisperV3 from '@/components/Common/Pages/ProjectDetail/WhisperV3/WhisperV3'
import OCRProductDetail from '@/components/Common/Pages/ProjectDetail/OCR'
import BKYC from '@/components/Common/Pages/ProjectDetail/BKYC'
import BEMO from '@/components/Common/Pages/ProjectDetail/Bemo'

import AISpeech2Text from '@/components/Common/Pages/AISpeech2Text'
import AIOcr from '@/components/Common/Pages/AIOcr'
import AIFaceRecognition from '@/components/Common/Pages/AIFaceRecognition'

import DifyAI from '@/components/Common/Pages/DifyAI'
import LibreChat from '@/components/Common/Pages/LibreChat'
import Llama3 from '@/components/Common/Pages/Llama3'
import StableDiffusion from '@/components/Common/Pages/StableDiffusion'
import AISolutions from '@/components/Common/Pages/AISolutions'
import DigitalTransformation from '@/components/Common/Pages/DigitalTransformation'
import Blockchain from '@/components/Common/Pages/Blockchain'
import GameMetaverse from '@/components/Common/Pages/GameMetaverse'

import MeraOffice from '@/components/Common/Pages/MeraOffice'
import NftMarketplace from '@/components/Common/Pages/NftMarketplace'

import CaseStudyList from '@/components/Common/Pages/CaseStudyList'
import CaseStudyDetail from '@/components/Common/Pages/CaseStudyDetail'
import { useAppDispatch } from '@/states/store'
import { getCategoriesPostsAction } from '@/states/features/categories/categories.action'

function PublicRoute() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    dispatch(getCategoriesPostsAction({ params: { limit: 10 } }))
  }

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<Top />} path="/" />
        {/* <Route element={<Top />} path="/about-us" /> */}
        <Route element={<Top />} path="/contact" />

        <Route element={<Blog />} path="/blog" />
        <Route element={<BlogDetail />} path="/blog/:blogSlug" />
        <Route element={<WhisperV3 />} path="/bap-whisper-large-v3" />
        <Route element={<OCRProductDetail />} path="/bap-ocr" />
        <Route element={<BKYC />} path="/BKYC" />
        <Route element={<BEMO />} path="/bemo-cloud" />
        <Route element={<ProjectDetail />} path="/product/:slug" />

        <Route path="speech-to-text" element={<AISpeech2Text />} />
        <Route path="ocr" element={<AIOcr />} />
        <Route path="face-recognition" element={<AIFaceRecognition />} />

        <Route path="chatbot-platform" element={<DifyAI />} />
        <Route path="chatbot-template" element={<LibreChat />} />
        <Route path="large-language-model" element={<Llama3 />} />
        <Route path="image-generator" element={<StableDiffusion />} />

        <Route path="ai-products" element={<AISolutions />} />
        <Route
          path="digital-transformation"
          element={<DigitalTransformation />}
        />
        <Route path="blockchain" element={<Blockchain />} />

        <Route path="game-metaverse" element={<GameMetaverse />} />
        <Route path="mera-office" element={<MeraOffice />} />

        <Route path="nft-marketplace" element={<NftMarketplace />} />

        <Route path="case-study" element={<CaseStudyList />} />
        <Route path="case-study/:id" element={<CaseStudyDetail />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  )
}

export default PublicRoute
