import './style.scss'

import classNames from 'classnames'

type data = {
  title: string
  description?: string
  image?: string
}

type PRFeaturesProps = {
  title: string
  description?: string
  image?: string
  data?: data[]
  isBackground?: boolean
}

const PRFeatures: React.FC<PRFeaturesProps> = ({
  title,
  description,
  image,
  data,
  isBackground,
}) => {
  const renderListItems = Array.isArray(data)
    ? data.map((item, index) => {
        return (
          <div className="wrapper-feature-content" key={index}>
            <div className="feature-content">
              <p className="feature-title">{item.title}</p>
              <p className="feature-description">{item.description}</p>
            </div>

            <div className="feature-image">
              <img src={item.image} alt={item.title} />
            </div>
          </div>
        )
      })
    : []

  return (
    <section
      className={classNames('pr-feature', { bg: isBackground })}
      id="pr-feature"
    >
      {isBackground && <div className="sub-background" />}
      <div className="main-container custom-container">
        <h2 className="title">{title}</h2>

        {description && <p className="description">{description}</p>}

        {image && (
          <div className="image-box">
            <img src={image} alt={title} />
          </div>
        )}

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default PRFeatures
