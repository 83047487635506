import './style.scss'
import './Offline.scss'

import classNames from 'classnames'
import React, { useEffect } from 'react'

// import { useLocation } from 'react-router-dom'
import ButtonGoToTop from '@/components/Common/Item/ButtonGoToTop'
import Contact from '@/components/Common/Pages/Contact'
import useOfflineRedirect from '@/hooks/useOfflineRedirect'

// import useScrollDefault from '@/components/CustomHook/useScrollDefault'
import Footer from './Footer'
import Header from './Header'
import Offline from './Offline'

interface Props {
  className?: string
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ className, children }) => {
  const isOnline = useOfflineRedirect()
  // const location = useLocation()
  // const path = location.pathname.substring(1)
  // useScrollDefault(path)

  useEffect(() => {
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0)
    }

    window.addEventListener('unload', handleBeforeUnload)

    return () => {
      window.scrollTo(0, 0)
      window.removeEventListener('unload', handleBeforeUnload)
    }
  }, [])

  return (
    <div className={classNames('wrapper', { className })}>
      <Header />
      {isOnline && (
        <>
          {children}

          <Contact />
          <Footer />
          <ButtonGoToTop />
        </>
      )}

      <Offline isActive={!isOnline} />

      {/* <ChatBox /> */}
    </div>
  )
}

export default Layout
