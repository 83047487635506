import './style.scss'

import classNames from 'classnames'
import React, { ReactNode, useRef } from 'react'
import Slider, { Settings } from 'react-slick'

import IconLeft from '@/assets/svg/Chevron_Left.svg'
import IconRight from '@/assets/svg/Chevron_Right.svg'

interface SlickSliderProps {
  settings?: Settings
  children: ReactNode
  isActions?: boolean
  className?: string
}

const SlickSlider: React.FC<SlickSliderProps> = ({
  children,
  settings,
  isActions,
  className,
}) => {
  const sliderRef = useRef<Slider | null>(null)

  const defaultSettings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const sliderSettings = { ...defaultSettings, ...settings }

  return (
    <div className={classNames('wrapper-slick--slider', className)}>
      <Slider {...sliderSettings} ref={sliderRef}>
        {children}
      </Slider>

      {isActions && (
        <div className="slider-btn-actions">
          <button
            className="btn-slick btn-left"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            <img src={IconLeft} alt="button slick" />
          </button>
          <button
            className="btn-slick btn-right"
            onClick={() => sliderRef.current?.slickNext()}
          >
            <img src={IconRight} alt="button slick" />
          </button>
        </div>
      )}
    </div>
  )
}

export default SlickSlider
