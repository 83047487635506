import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/chatbotPlatform/banner.png'
import ImageFeature1 from '@/assets/img/chatbotPlatform/feature_1.png'
import ImageFeature2 from '@/assets/img/chatbotPlatform/feature_2.png'
import ImageFeature3 from '@/assets/img/chatbotPlatform/feature_3.png'
import ImageFeature4 from '@/assets/img/chatbotPlatform/feature_4.png'
import ImageFeature5 from '@/assets/img/chatbotPlatform/feature_5.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'

const ChatbotPlatform: React.FC = () => {
  const { t } = useTranslation(['common'])

  const dataBanner = {
    titleVi: t('Chatbot Platform'),
    titleEn: t('Chatbot Platform'),
    titleJa: t('Chatbot Platform'),
    titleKo: t('Chatbot Platform'),
    descriptionVi: t('description_chatbot_platform'),
    descriptionEn: t('description_chatbot_platform'),
    descriptionJa: t('description_chatbot_platform'),
    descriptionKo: t('description_chatbot_platform'),
    image: ImageBanner,
  }

  const dataFeature = {
    titleVi: t('Features'),
    titleEn: t('Features'),
    titleJa: t('Features'),
    titleKo: t('Features'),
    items: [
      {
        titleVi: t('LLM Support'),
        titleEn: t('LLM Support'),
        titleJa: t('LLM Support'),
        titleKo: t('LLM Support'),
        descriptionVi: t('chatbot_platform_feature_1'),
        descriptionEn: t('chatbot_platform_feature_1'),
        descriptionJa: t('chatbot_platform_feature_1'),
        descriptionKo: t('chatbot_platform_feature_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('Prompt IDE'),
        titleEn: t('Prompt IDE'),
        titleJa: t('Prompt IDE'),
        titleKo: t('Prompt IDE'),
        descriptionVi: t('chatbot_platform_feature_2'),
        descriptionEn: t('chatbot_platform_feature_2'),
        descriptionJa: t('chatbot_platform_feature_2'),
        descriptionKo: t('chatbot_platform_feature_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('RAG Engine'),
        titleEn: t('RAG Engine'),
        titleJa: t('RAG Engine'),
        titleKo: t('RAG Engine'),
        descriptionVi: t('chatbot_platform_feature_3'),
        descriptionEn: t('chatbot_platform_feature_3'),
        descriptionJa: t('chatbot_platform_feature_3'),
        descriptionKo: t('chatbot_platform_feature_3'),
        image: ImageFeature3,
      },
      {
        titleVi: t('Agents'),
        titleEn: t('Agents'),
        titleJa: t('Agents'),
        titleKo: t('Agents'),
        descriptionVi: t('chatbot_platform_feature_4'),
        descriptionEn: t('chatbot_platform_feature_4'),
        descriptionJa: t('chatbot_platform_feature_4'),
        descriptionKo: t('chatbot_platform_feature_4'),
        image: ImageFeature4,
      },
      {
        titleVi: t('Continuous Operations'),
        titleEn: t('Continuous Operations'),
        titleJa: t('Continuous Operations'),
        titleKo: t('Continuous Operations'),
        descriptionVi: t('chatbot_platform_feature_5'),
        descriptionEn: t('chatbot_platform_feature_5'),
        descriptionJa: t('chatbot_platform_feature_5'),
        descriptionKo: t('chatbot_platform_feature_5'),
        image: ImageFeature5,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('FAQ from Chatbot Platform'),
    titleEn: t('FAQ from Chatbot Platform'),
    titleJa: t('FAQ from Chatbot Platform'),
    titleKo: t('FAQ from Chatbot Platform'),
    items: [
      {
        titleVi: t('Can I use Chatbot Platform for free?'),
        titleEn: t('Can I use Chatbot Platform for free?'),
        titleJa: t('Can I use Chatbot Platform for free?'),
        titleKo: t('Can I use Chatbot Platform for free?'),
        descriptionVi: t('chatbot_platform_faq_1'),
        descriptionEn: t('chatbot_platform_faq_1'),
        descriptionJa: t('chatbot_platform_faq_1'),
        descriptionKo: t('chatbot_platform_faq_1'),
      },
      {
        titleVi: t('Which models does Chatbot Platform support?'),
        titleEn: t('Which models does Chatbot Platform support?'),
        titleJa: t('Which models does Chatbot Platform support?'),
        titleKo: t('Which models does Chatbot Platform support?'),
        descriptionVi: t('chatbot_platform_faq_2'),
        descriptionEn: t('chatbot_platform_faq_2'),
        descriptionJa: t('chatbot_platform_faq_2'),
        descriptionKo: t('chatbot_platform_faq_2'),
      },
      {
        titleVi: t(
          'Can I integrate external plugins in Chatbot Platform applications?'
        ),
        titleEn: t(
          'Can I integrate external plugins in Chatbot Platform applications?'
        ),
        titleJa: t(
          'Can I integrate external plugins in Chatbot Platform applications?'
        ),
        titleKo: t(
          'Can I integrate external plugins in Chatbot Platform applications?'
        ),
        descriptionVi: t('chatbot_platform_faq_3'),
        descriptionEn: t('chatbot_platform_faq_3'),
        descriptionJa: t('chatbot_platform_faq_3'),
        descriptionKo: t('chatbot_platform_faq_3'),
      },
      {
        titleVi: t(
          'How long does it take to deploy an application on Chatbot Platform?'
        ),
        titleEn: t(
          'How long does it take to deploy an application on Chatbot Platform?'
        ),
        titleJa: t(
          'How long does it take to deploy an application on Chatbot Platform?'
        ),
        titleKo: t(
          'How long does it take to deploy an application on Chatbot Platform?'
        ),
        descriptionVi: t('chatbot_platform_faq_4'),
        descriptionEn: t('chatbot_platform_faq_4'),
        descriptionJa: t('chatbot_platform_faq_4'),
        descriptionKo: t('chatbot_platform_faq_4'),
      },
      {
        titleVi: t(
          'Can I customize the AI behavior in Chatbot Platform applications?'
        ),
        titleEn: t(
          'Can I customize the AI behavior in Chatbot Platform applications?'
        ),
        titleJa: t(
          'Can I customize the AI behavior in Chatbot Platform applications?'
        ),
        titleKo: t(
          'Can I customize the AI behavior in Chatbot Platform applications?'
        ),
        descriptionVi: t('chatbot_platform_faq_5'),
        descriptionEn: t('chatbot_platform_faq_5'),
        descriptionJa: t('chatbot_platform_faq_5'),
        descriptionKo: t('chatbot_platform_faq_5'),
      },
    ],
  }

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="try-demo min-w-[245px] h-[48px]"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_URL_DIFY}/signin?isdirectfromdemohub=true`,
                '_blank'
              )
            }
          >
            {t('Try Demo')}
          </Button>
        }
      />
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
        isBackground
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default ChatbotPlatform
