import './style.scss'

import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImageBemo from '@/assets/img/top/digital_trans_1.png'
// import Button from '@/components/Common/Item/Button'

const DigitalTransformation = () => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  return (
    <section className="digital_transformation" id="digital_transformation">
      <div className="main-container custom-container">
        <div className="box-title">
          <h2 className="title">{t('Digital Transformation')}</h2>
        </div>
        <p className="description">{t('digital_transformation_description')}</p>

        <Row gutter={[16, 16]} align="middle" justify="center" wrap>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18 }}
            md={{ span: 16 }}
            lg={{ span: 12 }}
          >
            <div
              className="case-study-item"
              onClick={() => navigate('digital-transformation')}
            >
              <img src={ImageBemo} alt="Bemo" />
              <p className="item-title">
                {t(
                  'Odoo-powered, comprehensive digital transformation solutions'
                )}
              </p>
            </div>
          </Col>
        </Row>

        {/* <div className="box-actions">
          <Button
            className="read-more w-[160px] h-[48px]"
            onClick={() => navigate('digital-transformation')}
          >
            {t('See all')}
          </Button>
        </div> */}
      </div>
    </section>
  )
}

export default DigitalTransformation
