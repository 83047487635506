import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/meraOffice/banner.png'
import ImageFeature1 from '@/assets/img/meraOffice/feature_1.png'
import ImageFeature2 from '@/assets/img/meraOffice/feature_2.png'
import ImageFeature3 from '@/assets/img/meraOffice/feature_3.png'
import ImageFeature4 from '@/assets/img/meraOffice/feature_4.png'
import ImageService1 from '@/assets/img/meraOffice/service_1.png'
import ImageService2 from '@/assets/img/meraOffice/service_2.png'
import ImageTech1 from '@/assets/img/meraOffice/tech_1.png'
import ImageTech2 from '@/assets/img/meraOffice/tech_2.png'
import ImageTech3 from '@/assets/img/meraOffice/tech_3.png'
import ImageTech4 from '@/assets/img/meraOffice/tech_4.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'
import Services from '@/templates/PRServices'
import Technology from '@/templates/PRTechnology'

const MeraOffice: React.FC = () => {
  const { t } = useTranslation(['common'])

  const dataBanner = {
    titleVi: t('Office in the Metaverse.'),
    titleEn: t('Office in the Metaverse.'),
    titleJa: t('Office in the Metaverse.'),
    titleKo: t('Office in the Metaverse.'),
    descriptionVi: t('mera_office_description'),
    descriptionEn: t('mera_office_description'),
    descriptionJa: t('mera_office_description'),
    descriptionKo: t('mera_office_description'),
    image: ImageBanner,
  }

  const dataServices = {
    titleVi: t('Our Services'),
    titleEn: t('Our Services'),
    titleJa: t('Our Services'),
    titleKo: t('Our Services'),
    items: [
      {
        titleVi: t('mera_office_service_title_1'),
        titleEn: t('mera_office_service_title_1'),
        titleJa: t('mera_office_service_title_1'),
        titleKo: t('mera_office_service_title_1'),
        descriptionVi: t('mera_office_service_description_1'),
        descriptionEn: t('mera_office_service_description_1'),
        descriptionJa: t('mera_office_service_description_1'),
        descriptionKo: t('mera_office_service_description_1'),
        image: ImageService1,
      },
      {
        titleVi: t('mera_office_service_title_2'),
        titleEn: t('mera_office_service_title_2'),
        titleJa: t('mera_office_service_title_2'),
        titleKo: t('mera_office_service_title_2'),
        descriptionVi: t('mera_office_service_description_2'),
        descriptionEn: t('mera_office_service_description_2'),
        descriptionJa: t('mera_office_service_description_2'),
        descriptionKo: t('mera_office_service_description_2'),
        image: ImageService2,
      },
    ],
  }

  const dataFeature = {
    titleVi: t('Key Innovations'),
    titleEn: t('Key Innovations'),
    titleJa: t('Key Innovations'),
    titleKo: t('Key Innovations'),
    items: [
      {
        titleVi: t('mera_office_feature_title_1'),
        titleEn: t('mera_office_feature_title_1'),
        titleJa: t('mera_office_feature_title_1'),
        titleKo: t('mera_office_feature_title_1'),
        descriptionVi: t('mera_office_feature_description_1'),
        descriptionEn: t('mera_office_feature_description_1'),
        descriptionJa: t('mera_office_feature_description_1'),
        descriptionKo: t('mera_office_feature_description_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('mera_office_feature_title_2'),
        titleEn: t('mera_office_feature_title_2'),
        titleJa: t('mera_office_feature_title_2'),
        titleKo: t('mera_office_feature_title_2'),
        descriptionVi: t('mera_office_feature_description_2'),
        descriptionEn: t('mera_office_feature_description_2'),
        descriptionJa: t('mera_office_feature_description_2'),
        descriptionKo: t('mera_office_feature_description_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('mera_office_feature_title_3'),
        titleEn: t('mera_office_feature_title_3'),
        titleJa: t('mera_office_feature_title_3'),
        titleKo: t('mera_office_feature_title_3'),
        descriptionVi: t('mera_office_feature_description_3'),
        descriptionEn: t('mera_office_feature_description_3'),
        descriptionJa: t('mera_office_feature_description_3'),
        descriptionKo: t('mera_office_feature_description_3'),
        image: ImageFeature3,
      },
      {
        titleVi: t('mera_office_feature_title_4'),
        titleEn: t('mera_office_feature_title_4'),
        titleJa: t('mera_office_feature_title_4'),
        titleKo: t('mera_office_feature_title_4'),
        descriptionVi: t('mera_office_feature_description_4'),
        descriptionEn: t('mera_office_feature_description_4'),
        descriptionJa: t('mera_office_feature_description_4'),
        descriptionKo: t('mera_office_feature_description_4'),
        image: ImageFeature4,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('FAQ from Marketplace'),
    titleEn: t('FAQ from Marketplace'),
    titleJa: t('FAQ from Marketplace'),
    titleKo: t('FAQ from Marketplace'),
    items: [
      {
        titleVi: t('faq_mera_office_title_1'),
        titleEn: t('faq_mera_office_title_1'),
        titleJa: t('faq_mera_office_title_1'),
        titleKo: t('faq_mera_office_title_1'),
        descriptionVi: t('faq_mera_office_description_1'),
        descriptionEn: t('faq_mera_office_description_1'),
        descriptionJa: t('faq_mera_office_description_1'),
        descriptionKo: t('faq_mera_office_description_1'),
      },
      {
        titleVi: t('faq_mera_office_title_2'),
        titleEn: t('faq_mera_office_title_2'),
        titleJa: t('faq_mera_office_title_2'),
        titleKo: t('faq_mera_office_title_2'),
        descriptionVi: t('faq_mera_office_description_2'),
        descriptionEn: t('faq_mera_office_description_2'),
        descriptionJa: t('faq_mera_office_description_2'),
        descriptionKo: t('faq_mera_office_description_2'),
      },
      {
        titleVi: t('faq_mera_office_title_3'),
        titleEn: t('faq_mera_office_title_3'),
        titleJa: t('faq_mera_office_title_3'),
        titleKo: t('faq_mera_office_title_3'),
        descriptionVi: t('faq_mera_office_description_3'),
        descriptionEn: t('faq_mera_office_description_3'),
        descriptionJa: t('faq_mera_office_description_3'),
        descriptionKo: t('faq_mera_office_description_3'),
      },
      {
        titleVi: t('faq_mera_office_title_4'),
        titleEn: t('faq_mera_office_title_4'),
        titleJa: t('faq_mera_office_title_4'),
        titleKo: t('faq_mera_office_title_4'),
        descriptionVi: t('faq_mera_office_description_4'),
        descriptionEn: t('faq_mera_office_description_4'),
        descriptionJa: t('faq_mera_office_description_4'),
        descriptionKo: t('faq_mera_office_description_4'),
      },
      {
        titleVi: t('faq_mera_office_title_5'),
        titleEn: t('faq_mera_office_title_5'),
        titleJa: t('faq_mera_office_title_5'),
        titleKo: t('faq_mera_office_title_5'),
        descriptionVi: t('faq_mera_office_description_5'),
        descriptionEn: t('faq_mera_office_description_5'),
        descriptionJa: t('faq_mera_office_description_5'),
        descriptionKo: t('faq_mera_office_description_5'),
      },
    ],
  }

  const dataTechnology = {
    titleVi: t('Technology'),
    titleEn: t('Technology'),
    titleJa: t('Technology'),
    titleKo: t('Technology'),
    items: [
      {
        titleVi: t('Metaverse'),
        titleEn: t('Metaverse'),
        titleJa: t('Metaverse'),
        titleKo: t('Metaverse'),
        image: ImageTech1,
      },
      {
        titleVi: t('Gameplay'),
        titleEn: t('Gameplay'),
        titleJa: t('Gameplay'),
        titleKo: t('Gameplay'),
        image: ImageTech2,
      },
      {
        titleVi: t('Online Payment Service'),
        titleEn: t('Online Payment Service'),
        titleJa: t('Online Payment Service'),
        titleKo: t('Online Payment Service'),
        image: ImageTech3,
      },
      {
        titleVi: t('Streaming'),
        titleEn: t('Streaming'),
        titleJa: t('Streaming'),
        titleKo: t('Streaming'),
        image: ImageTech4,
      },
    ],
  }

  const dataServiceItems = dataServices.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataTechnologyItems = dataTechnology.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="try-demo min-w-[245px] h-[48px]"
            onClick={() =>
              window.open(process.env.REACT_APP_URL_MERA_OFFICE, '_blank')
            }
          >
            {t('Try Demo')}
          </Button>
        }
      />
      <Services
        title={useGetTextLanguage('title', dataServices)}
        data={dataServiceItems}
      />
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
      />
      <Technology
        title={useGetTextLanguage('title', dataTechnology)}
        data={dataTechnologyItems}
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default MeraOffice
