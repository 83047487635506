import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'
import { CATEGORIES } from '@/contants/categories'
import { useCategoryId } from '@/hooks/useCategoryId'

import CardSlider from '../../Item/CardSlider'
import AboutUs from '../AboutUs'
import Banner from './Banner'
import Introduce from './Introduce'
import Solution from './Solution'
import Technologies from './Technologies'

const Blockchain: React.FC = () => {
  const blockchain = useCategoryId(CATEGORIES.Blockchain)

  return (
    <Layout>
      <Banner />
      <Solution />
      <Introduce />
      <Technologies />
      <CardSlider categoryId={blockchain} />
      <AboutUs className="mt-[60px] mb-[60px]" />
    </Layout>
  )
}

export default Blockchain
