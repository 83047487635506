import { createSlice } from '@reduxjs/toolkit'

import { postAudioFileAction } from './speechTotext.action'

const initialState: SpeechToText.SpeechToTextState = {
  isLoading: false,
  audioFileToText: '',
  errorContact: null,
}

export const { actions, reducer } = createSlice({
  name: 'SpeechToText',
  initialState,
  reducers: {
    resetErrorContact: (state) => {
      state.errorContact = null
    },
    resetFileToText: (state) => {
      state.audioFileToText = ''
    },
    setFileToText: (state, action) => {
      state.audioFileToText = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAudioFileAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postAudioFileAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.audioFileToText = action.payload.data
      })
      .addCase(postAudioFileAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorContact = action.payload
      })
  },
})

export default actions
