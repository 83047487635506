/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react'

export interface IDCardInfo {
  full_name?: string | null
  id_card_number?: string | null
  birthday?: string | null
  nationality?: string | null
  hometown?: string | null
  address?: string | null
  date_of_expiry?: string | null
  driver_license_class?: string | null
  place_of_issue?: string | null
}

export interface TableIDCardProps {
  data: IDCardInfo | null
}

const TableIDCard: React.FC<TableIDCardProps> = ({ data }) => {
  const full_name = data?.full_name || '............................'
  const id_card_number = data?.id_card_number || '............................'
  const birthday = data?.birthday || '............................'
  const nationality = data?.nationality || '............................'
  const hometown = data?.hometown || '............................'
  const address = data?.address || '............................'
  const date_of_expiry = data?.date_of_expiry || '............................'
  const driver_license_class =
    data?.driver_license_class || '............................'
  const place_of_issue = data?.place_of_issue || '............................'

  return (
    <div className="flex flex-col gap-2 justify-start items-cente">
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Full Name:
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {full_name}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">Number:</span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {id_card_number}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Birthday:
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {birthday}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Nationality:
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {nationality}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Hometown:
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {hometown}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Address:
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {address}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">Class:</span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {driver_license_class}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Date of Expiry:
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {date_of_expiry}
        </span>
      </div>
      <div className="flex justify-start items-end gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Place of issue:
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          {place_of_issue}
        </span>
      </div>
    </div>
  )
}

export default TableIDCard
