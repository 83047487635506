import { createSlice } from '@reduxjs/toolkit'

import {
  faceRecognitionAction,
  identificationInfoAction,
} from './faceRecognition.action'

const initialState: FaceRecognition.FaceRecognitionState = {
  isLoading: false,
  persons: [],
  errorContact: null,
}

export const { actions, reducer } = createSlice({
  name: 'faceRecognition',
  initialState,
  reducers: {
    resetErrorContact: (state) => {
      state.errorContact = null
    },
    resetPersons: (state) => {
      state.persons = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(faceRecognitionAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(faceRecognitionAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.persons = action.payload.data.persons || []
      })
      .addCase(faceRecognitionAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorContact = action.payload
      })
      .addCase(identificationInfoAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(identificationInfoAction.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(identificationInfoAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorContact = action.payload
      })
  },
})

export default actions
