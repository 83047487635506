import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'
import { CATEGORIES } from '@/contants/categories'
import { useCategoryId } from '@/hooks/useCategoryId'

import CardSlider from '../../Item/CardSlider'
import AboutUs from '../AboutUs'
import Banner from './Banner'
import CreateGame from './CreateGame'
import Introduce from './Introduce'
import Technologies from './Technologies'

const GameMetaverse: React.FC = () => {
  const gameMetaverse = useCategoryId(CATEGORIES.GameMetaverse)

  return (
    <Layout>
      <Banner />
      <Introduce />
      <Technologies />
      <CreateGame />
      <CardSlider categoryId={gameMetaverse} />
      <AboutUs className="mt-[60px] mb-[60px]" />
    </Layout>
  )
}

export default GameMetaverse
