import { createSlice } from '@reduxjs/toolkit'

import { getInfoAuthenticateAction } from './thirdParty.action'

const initialState: {
  isLoading: boolean
  errorContact: any
} = {
  isLoading: false,
  errorContact: null,
}

export const { actions, reducer } = createSlice({
  name: 'thirdParty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInfoAuthenticateAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getInfoAuthenticateAction.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getInfoAuthenticateAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorContact = action.payload
      })
  },
})

export default actions
