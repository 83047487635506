import './style.scss'

import { Pagination, PaginationProps } from 'antd'
import React from 'react'

interface CustomPaginationProps extends PaginationProps {
  total?: number
  className?: string
  onChange?: (value: number) => void
  current?: number
  pageSize?: number
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  total,
  className,
  onChange,
  current,
  pageSize,
}) => {
  return (
    <div className="custom-pagination">
      <Pagination
        className={className}
        showLessItems
        total={total}
        onChange={onChange}
        current={current}
        pageSize={pageSize}
      />
    </div>
  )
}

export default CustomPagination
