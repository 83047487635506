import './style.scss'

import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import IconTicked from '../../../../assets/img/Circle_Check.png'
import IconWhyChooseUs1 from '../../../../assets/img/why-choose-us-1.png'
import IconWhyChooseUs1Color from '../../../../assets/img/why-choose-us-1-color.png'
import IconWhyChooseUs2 from '../../../../assets/img/why-choose-us-2.png'
import IconWhyChooseUs2Color from '../../../../assets/img/why-choose-us-2-color.png'
import IconWhyChooseUs3 from '../../../../assets/img/why-choose-us-3.png'
import IconWhyChooseUs3Color from '../../../../assets/img/why-choose-us-3-color.png'
import IconWhyChooseUs4 from '../../../../assets/img/why-choose-us-4.png'
import IconWhyChooseUs4Color from '../../../../assets/img/why-choose-us-4-color.png'
import SubIconWhyChooseUs1 from '../../../../assets/svg/why-choose-us-1.svg'
import SubIconWhyChooseUs2 from '../../../../assets/svg/why-choose-us-2.svg'
import SubIconWhyChooseUs3 from '../../../../assets/svg/why-choose-us-3.svg'
import SubIconWhyChooseUs4 from '../../../../assets/svg/why-choose-us-4.svg'

const AboutUs: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation(['common'])

  const [currentTab, setCurrentTab] = useState(0)

  const listContents = [
    {
      title: t('about_us_t1'),
      icon: IconWhyChooseUs1,
      iconColor: IconWhyChooseUs1Color,
      subIcon: SubIconWhyChooseUs1,
      description: t('about_us_d1'),
    },
    {
      title: t('about_us_t2'),
      icon: IconWhyChooseUs2,
      iconColor: IconWhyChooseUs2Color,
      subIcon: SubIconWhyChooseUs2,
      description: t('about_us_d2'),
    },
    {
      title: t('about_us_t3'),
      icon: IconWhyChooseUs3,
      iconColor: IconWhyChooseUs3Color,
      subIcon: SubIconWhyChooseUs3,
      description: t('about_us_d3'),
    },
    {
      title: t('about_us_t4'),
      icon: IconWhyChooseUs4,
      iconColor: IconWhyChooseUs4Color,
      subIcon: SubIconWhyChooseUs4,
      description: t('about_us_d4'),
    },
  ]

  const renderTabList = listContents.map((item, index) => {
    const isActive = index === currentTab
    return (
      <div
        className={classNames('tab-item', { active: isActive })}
        key={index}
        onClick={() => setCurrentTab(index)}
      >
        <img src={isActive ? item.iconColor : item.icon} alt={item.title} />
        <p className="title">{item.title}</p>
      </div>
    )
  })

  return (
    <section className={classNames('about-us', className)} id="about-us">
      <div className="main-container custom-container">
        <h1 className="text-black text-[36px] text-left uppercase font-bold">
          {t('Why BAP?')}
        </h1>
        <p className="text-left text-[#383838] leading-6 mt-[24px] mb-[36px]">
          {t('about_us_des_1')}
        </p>

        <div className="list-contents">
          <div className="tab-list-items">{renderTabList}</div>
          <div className="tab-contents">
            <img src={IconTicked} alt="" width={24} height={24} />
            <span>{listContents[currentTab].description}</span>

            <img
              src={listContents[currentTab].subIcon}
              alt=""
              className="sub-icon"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
