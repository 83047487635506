export const capitalizeFirstLetter = (value: string) => {
  if (!value) return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const cleanedObj = <T extends Record<string, any>>(obj: T): T => {
  const cleanedObj: Partial<T> = {}

  Object.keys(obj).forEach((key) => {
    let value = obj[key]

    if (typeof value === 'string') {
      value = value.trim()
    }

    if (value !== null && value !== undefined && value !== '') {
      cleanedObj[key as keyof T] = value
    }
  })

  return cleanedObj as T
}
