import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/chatbotTemplate/banner.png'
import ImageFeature1 from '@/assets/img/speech2text/favorite_features_1.png'
import ImageFeature2 from '@/assets/img/speech2text/favorite_features_2.png'
import ImageFeature3 from '@/assets/img/speech2text/favorite_features_3.png'
import ImageFeature4 from '@/assets/img/speech2text/favorite_features_4.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'

import AudioComponent from './Audio'
// import FastAndAccurately from './FastAndAccurately'
import StepBySteps from './StepBySteps'

const SpeechToText: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()

  const dataBanner = {
    titleVi: t('Chatbot Template'),
    titleEn: t('Chatbot Template'),
    titleJa: t('Chatbot Template'),
    titleKo: t('Chatbot Template'),
    descriptionVi: t('description_chatbot_platform'),
    descriptionEn: t('description_chatbot_platform'),
    descriptionJa: t('description_chatbot_platform'),
    descriptionKo: t('description_chatbot_platform'),
    image: ImageBanner,
  }

  const dataFeature = {
    titleVi: t('Favorite Features for'),
    titleEn: t('Favorite Features for'),
    titleJa: t('Favorite Features for'),
    titleKo: t('Favorite Features for'),
    items: [
      {
        titleVi: t('favorite_features_title_1'),
        titleEn: t('favorite_features_title_1'),
        titleJa: t('favorite_features_title_1'),
        titleKo: t('favorite_features_title_1'),
        descriptionVi: t('favorite_features_description_1'),
        descriptionEn: t('favorite_features_description_1'),
        descriptionJa: t('favorite_features_description_1'),
        descriptionKo: t('favorite_features_description_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('favorite_features_title_2'),
        titleEn: t('favorite_features_title_2'),
        titleJa: t('favorite_features_title_2'),
        titleKo: t('favorite_features_title_2'),
        descriptionVi: t('favorite_features_description_2'),
        descriptionEn: t('favorite_features_description_2'),
        descriptionJa: t('favorite_features_description_2'),
        descriptionKo: t('favorite_features_description_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('favorite_features_title_3'),
        titleEn: t('favorite_features_title_3'),
        titleJa: t('favorite_features_title_3'),
        titleKo: t('favorite_features_title_3'),
        descriptionVi: t('favorite_features_description_3'),
        descriptionEn: t('favorite_features_description_3'),
        descriptionJa: t('favorite_features_description_3'),
        descriptionKo: t('favorite_features_description_3'),
        image: ImageFeature3,
      },
      {
        titleVi: t('favorite_features_title_4'),
        titleEn: t('favorite_features_title_4'),
        titleJa: t('favorite_features_title_4'),
        titleKo: t('favorite_features_title_4'),
        descriptionVi: t('favorite_features_description_4'),
        descriptionEn: t('favorite_features_description_4'),
        descriptionJa: t('favorite_features_description_4'),
        descriptionKo: t('favorite_features_description_4'),
        image: ImageFeature4,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('Frequently Asked Questions about Speech-to-Text Conversion'),
    titleEn: t('Frequently Asked Questions about Speech-to-Text Conversion'),
    titleJa: t('Frequently Asked Questions about Speech-to-Text Conversion'),
    titleKo: t('Frequently Asked Questions about Speech-to-Text Conversion'),
    items: [
      {
        titleVi: t('faq_title_1'),
        titleEn: t('faq_title_1'),
        titleJa: t('faq_title_1'),
        titleKo: t('faq_title_1'),
        descriptionVi: t('faq_description_1'),
        descriptionEn: t('faq_description_1'),
        descriptionJa: t('faq_description_1'),
        descriptionKo: t('faq_description_1'),
      },
      {
        titleVi: t('faq_title_2'),
        titleEn: t('faq_title_2'),
        titleJa: t('faq_title_2'),
        titleKo: t('faq_title_2'),
        descriptionVi: t('faq_description_2'),
        descriptionEn: t('faq_description_2'),
        descriptionJa: t('faq_description_2'),
        descriptionKo: t('faq_description_2'),
      },
      {
        titleVi: t('faq_title_3'),
        titleEn: t('faq_title_3'),
        titleJa: t('faq_title_3'),
        titleKo: t('faq_title_3'),
        descriptionVi: t('faq_description_3'),
        descriptionEn: t('faq_description_3'),
        descriptionJa: t('faq_description_3'),
        descriptionKo: t('faq_description_3'),
      },
    ],
  }

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="explore_more min-w[245px] h-[48px]"
            onClick={() => scrollToSection('demo-speech2text', -100)}
          >
            {t('Convert audio to text now')}
          </Button>
        }
      >
        <AudioComponent />
      </Banner>
      <StepBySteps />
      {/* <FastAndAccurately /> */}
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
        isBackground
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default SpeechToText
