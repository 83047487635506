import './style.scss'

import CardSlider from '@/components/Common/Item/CardSlider'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import Bannervideo from '@/components/Common/Pages/BannerVideo'

import AISection from './AISection'
import Awards from './Awards'
import BlockchainSection from './BlockchainSection'
import CaseStudy from './CaseStudy'
import Clients from './Clients'
import DigitalTransformation from './DigitalTransformation'
// import OurPartner from '@/components/Common/Pages/Top/OurPartner/indexV2'
// import ERPSection from './ERPSection'

const Home: React.FC = () => {
  return (
    <Layout>
      <Bannervideo />
      <div className="section-wrapper">
        <AISection />
        <CaseStudy />
      </div>
      <div className="section-wrapper second">
        <DigitalTransformation />
        <BlockchainSection />
      </div>
      <CardSlider categoryId="all" />
      <Awards />
      <Clients />
      {/* <ERPSection /> */}
      {/* <OurPartner /> */}
    </Layout>
  )
}

export default Home
