import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/chatbotTemplate/banner.png'
import ImageFeature1 from '@/assets/img/chatbotTemplate/feature_1.png'
import ImageFeature2 from '@/assets/img/chatbotTemplate/feature_2.png'
import ImageFeature3 from '@/assets/img/chatbotTemplate/feature_3.png'
import ImageFeature4 from '@/assets/img/chatbotTemplate/feature_4.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'

const ChatbotTemplate: React.FC = () => {
  const { t } = useTranslation(['common'])

  const dataBanner = {
    titleVi: t('Chatbot Template'),
    titleEn: t('Chatbot Template'),
    titleJa: t('Chatbot Template'),
    titleKo: t('Chatbot Template'),
    descriptionVi: t('description_chatbot_platform'),
    descriptionEn: t('description_chatbot_platform'),
    descriptionJa: t('description_chatbot_platform'),
    descriptionKo: t('description_chatbot_platform'),
    image: ImageBanner,
  }

  const dataFeature = {
    titleVi: t('Features'),
    titleEn: t('Features'),
    titleJa: t('Features'),
    titleKo: t('Features'),
    items: [
      {
        titleVi: t('Open-Source and Free"'),
        titleEn: t('Open-Source and Free"'),
        titleJa: t('Open-Source and Free"'),
        titleKo: t('Open-Source and Free"'),
        descriptionVi: t('chatbot_template_feature_1'),
        descriptionEn: t('chatbot_template_feature_1'),
        descriptionJa: t('chatbot_template_feature_1'),
        descriptionKo: t('chatbot_template_feature_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('Multi-Provider AI Support'),
        titleEn: t('Multi-Provider AI Support'),
        titleJa: t('Multi-Provider AI Support'),
        titleKo: t('Multi-Provider AI Support'),
        descriptionVi: t('chatbot_template_feature_2'),
        descriptionEn: t('chatbot_template_feature_2'),
        descriptionJa: t('chatbot_template_feature_2'),
        descriptionKo: t('chatbot_template_feature_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('Customizable and Extensible'),
        titleEn: t('Customizable and Extensible'),
        titleJa: t('Customizable and Extensible'),
        titleKo: t('Customizable and Extensible'),
        descriptionVi: t('chatbot_template_feature_3'),
        descriptionEn: t('chatbot_template_feature_3'),
        descriptionJa: t('chatbot_template_feature_3'),
        descriptionKo: t('chatbot_template_feature_3'),
        image: ImageFeature3,
      },
      {
        titleVi: t('Multilingual Support'),
        titleEn: t('Multilingual Support'),
        titleJa: t('Multilingual Support'),
        titleKo: t('Multilingual Support'),
        descriptionVi: t('chatbot_template_feature_4'),
        descriptionEn: t('chatbot_template_feature_4'),
        descriptionJa: t('chatbot_template_feature_4'),
        descriptionKo: t('chatbot_template_feature_4'),
        image: ImageFeature4,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('FAQ from Chatbot Template'),
    titleEn: t('FAQ from Chatbot Template'),
    titleJa: t('FAQ from Chatbot Template'),
    titleKo: t('FAQ from Chatbot Template'),
    items: [
      {
        titleVi: t(
          'How is Chatbot Template different from ChatGPT or other chatbot services?'
        ),
        titleEn: t(
          'How is Chatbot Template different from ChatGPT or other chatbot services?'
        ),
        titleJa: t(
          'How is Chatbot Template different from ChatGPT or other chatbot services?'
        ),
        titleKo: t(
          'How is Chatbot Template different from ChatGPT or other chatbot services?'
        ),
        descriptionVi: t('chatbot_template_faq_1'),
        descriptionEn: t('chatbot_template_faq_1'),
        descriptionJa: t('chatbot_template_faq_1'),
        descriptionKo: t('chatbot_template_faq_1'),
      },
      {
        titleVi: t('Can I use my own models with Chatbot Template?'),
        titleEn: t('Can I use my own models with Chatbot Template?'),
        titleJa: t('Can I use my own models with Chatbot Template?'),
        titleKo: t('Can I use my own models with Chatbot Template?'),
        descriptionVi: t('chatbot_template_faq_2'),
        descriptionEn: t('chatbot_template_faq_2'),
        descriptionJa: t('chatbot_template_faq_2'),
        descriptionKo: t('chatbot_template_faq_2'),
      },
      {
        titleVi: t('What platforms are supported by Chatbot Template?'),
        titleEn: t('What platforms are supported by Chatbot Template?'),
        titleJa: t('What platforms are supported by Chatbot Template?'),
        titleKo: t('What platforms are supported by Chatbot Template?'),
        descriptionVi: t('chatbot_template_faq_3'),
        descriptionEn: t('chatbot_template_faq_3'),
        descriptionJa: t('chatbot_template_faq_3'),
        descriptionKo: t('chatbot_template_faq_3'),
      },
      {
        titleVi: t('Is Chatbot Template free to use?'),
        titleEn: t('Is Chatbot Template free to use?'),
        titleJa: t('Is Chatbot Template free to use?'),
        titleKo: t('Is Chatbot Template free to use?'),
        descriptionVi: t('chatbot_template_faq_4'),
        descriptionEn: t('chatbot_template_faq_4'),
        descriptionJa: t('chatbot_template_faq_4'),
        descriptionKo: t('chatbot_template_faq_4'),
      },
      {
        titleVi: t('How do I get started with Chatbot Template?'),
        titleEn: t('How do I get started with Chatbot Template?'),
        titleJa: t('How do I get started with Chatbot Template?'),
        titleKo: t('How do I get started with Chatbot Template?'),
        descriptionVi: t('chatbot_template_faq_5'),
        descriptionEn: t('chatbot_template_faq_5'),
        descriptionJa: t('chatbot_template_faq_5'),
        descriptionKo: t('chatbot_template_faq_5'),
      },
    ],
  }

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="try-demo min-w-[245px] h-[48px]"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_URL_LIBRE_CHAT}/login?isdirectfromdemohub=true`,
                '_blank'
              )
            }
          >
            {t('Try Demo')}
          </Button>
        }
      />
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
        isBackground
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default ChatbotTemplate
