import * as Yup from 'yup'

import { emailRegExp, phoneRegExp } from '@/contants'

export const contactFormSchema = (t: any) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      // .min(2, 'Minimum 2 characters')
      .max(
        50,
        t('Full Name must be 50 characters or less.', { ns: 'validation' })
      )
      .required(t('Full Name is required.', { ns: 'validation' })),
    email: Yup.string()
      .trim()
      .matches(
        emailRegExp,
        t('Please enter a valid email address.', { ns: 'validation' })
      )
      .email(t('Please enter a valid email address.', { ns: 'validation' }))
      .required(t('Email is required.', { ns: 'validation' })),
    phoneNumber: Yup.string()
      .trim()
      .matches(
        phoneRegExp,
        t('Please enter a valid phone number.', { ns: 'validation' })
      )
      .required(t('Phone Number is required.', { ns: 'validation' })),
    companyName: Yup.string()
      .trim()
      // .min(5, 'Minimum 5 characters')
      .max(
        255,
        t('Company name must be 255 characters or less.', { ns: 'validation' })
      )
      .required(t('Company is required.', { ns: 'validation' })),
    message: Yup.string()
      .trim()
      // .min(40, 'Minimum 40 characters')
      .max(
        2000,
        t('Message must be 2000 characters or less.', { ns: 'validation' })
      )
      .required(t('Message is required.', { ns: 'validation' })),
  })
}
