import './style.scss'

import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImageCase1 from '@/assets/img/top/case_1.png'
import ImageCase2 from '@/assets/img/top/case_2.png'
import ImageCase3 from '@/assets/img/top/case_3.png'
import ImageCase4 from '@/assets/img/top/case_4.png'
import ImageCase5 from '@/assets/img/top/case_5.png'
import ImageCase6 from '@/assets/img/top/case_6.png'
import ImageCase7 from '@/assets/img/top/case_7.png'
import ImageCase8 from '@/assets/img/top/case_8.png'
// import Button from '@/components/Common/Item/Button'

const CaseStudy = () => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  const listCase = [
    {
      title: t('Speech to Text'),
      image: ImageCase1,
      path: 'speech-to-text',
    },
    {
      title: t('Optical Character Recognition'),
      image: ImageCase2,
      path: 'ocr',
    },
    {
      title: t('Face Recognition'),
      image: ImageCase3,
      path: 'face-recognition',
    },
    {
      title: t('Mera Office'),
      image: ImageCase4,
      path: 'mera-office',
    },
    {
      title: t('Chatbot Platform'),
      image: ImageCase5,
      path: 'chatbot-platform',
    },
    {
      title: t('Chatbot Template'),
      image: ImageCase6,
      path: 'chatbot-template',
    },
    {
      title: t('Large Language Model'),
      image: ImageCase7,
      path: 'large-language-model',
    },
    {
      title: t('Image Generator'),
      image: ImageCase8,
      path: 'image-generator',
    },
  ]

  const listItems = Array.isArray(listCase)
    ? listCase?.map((item, index) => (
        <Col key={index} xs={24} sm={12} md={6}>
          <div className="case-study-item" onClick={() => navigate(item.path)}>
            <img src={item.image} alt={item.title} />

            <p className="item-title">{item.title}</p>
          </div>
        </Col>
      ))
    : []

  return (
    <section className="case_study" id="case_study">
      <div className="main-container custom-container">
        <div className="box-title">
          <h2 className="title">{t('Case Study')}</h2>
        </div>
        <p className="description">{t('case_study_description')}</p>

        <div className="list-card-items">
          <Row gutter={[16, 16]} className="w-full">
            {listItems}
          </Row>
        </div>

        {/* <div className="box-actions">
          <Button
            className="read-more w-[160px] h-[48px]"
            onClick={() => navigate('digital-transformation')}
          >
            {t('See all')}
          </Button>
        </div> */}
      </div>
    </section>
  )
}

export default CaseStudy
