/* eslint-disable sonarjs/cognitive-complexity*/
import './style.scss'

import { UploadFile } from 'antd'
import classNames from 'classnames'
import * as faceapi from 'face-api.js'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ImageUpload from '@/assets/img/upload_image.png'
import Loading from '@/components/Common/Item/Loading'
import Modal from '@/components/Common/Item/Modal'
import ModalWebcam from '@/components/Common/Item/ModalWebcam'
import UploadDragger from '@/components/Common/Item/UploadDragger'
import { FEATURE_TABS } from '@/contants/face-recognition'
import { getAccept, TYPE_FILE_IMAGE } from '@/contants/upload'
import { faceRecognitionAction } from '@/states/features/faceRecognition/faceRecognition.action'
import { useAppDispatch, useAppSelector } from '@/states/store'
import { canvasToFile } from '@/utils/file'

import AddNewData from './../Tabs/AddNewData'
import FaceRecognition from './../Tabs/FaceRecognition'

const FaceRecognitionDemo: React.FC = () => {
  const { t } = useTranslation(['common', 'validation', 'errors'])
  const dispatch = useAppDispatch()

  const intervalRef = useRef<any>(null)

  const { isLoading } = useAppSelector((state) => state.faceRecognition)

  const [fileAddNew, setFileAddNew] = useState<UploadFile | File | null>(null)
  const [fileFaceRecognition, setFileFaceRecognition] = useState<
    UploadFile | File | null
  >(null)
  const [currentTab, setCurrentTab] = useState(FEATURE_TABS.ADD_NEW)
  const [isVisible, setIsVisible] = useState(false)
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false)
  const [loadingFace, setLoadingFace] = useState(true)
  const [loading, setLoading] = useState(false)
  const [detectAllFacesComplete, setDetectAllFacesComplete] = useState(true)

  // State Add New
  const [toggleReset_A, setToggleReset_A] = useState(false)
  const [faces_A, setFaces_A] = useState<HTMLCanvasElement[]>([])
  const [faceSelected_A, setFaceSelected_A] = useState<number>(0)
  const [listInfo_A, setListInfo_A] = useState<FaceRecognition.Persons[]>([])
  const [isLoading_A, setIsLoading_A] = useState(false)
  const [facesExtracted_A, setFacesExtracted_A] = useState(false)

  // State Face Recognition
  const [toggleReset_F, setToggleReset_F] = useState(false)
  const [faces_F, setFaces_F] = useState<HTMLCanvasElement[]>([])
  const [faceSelected_F, setFaceSelected_F] = useState<number>(0)
  const [listInfo_F, setListInfo_F] = useState<FaceRecognition.Persons[]>([])
  const [isLoading_F, setIsLoading_F] = useState(false)
  const [facesExtracted_F, setFacesExtracted_F] = useState(false)

  const hasLoading = isLoading || loading || loadingFace
  const isTabAddNewData = currentTab === FEATURE_TABS.ADD_NEW
  const isTabFaceRecognition = currentTab === FEATURE_TABS.FACE_RECOGNITION
  const initialValues = {
    fullName: '',
    // country: '',
    // birthday: '',
    gender: '',
    // age: '',
    // email: '',
    // phoneNumber: '',
  }

  const handleTryAgain = () => {
    setStateFile(null)
    setStateReset()
    // window.location.reload()
  }

  const setStateFile = (file: UploadFile | File | null) => {
    switch (currentTab) {
      case FEATURE_TABS.ADD_NEW:
        setFileAddNew(file)
        return
      case FEATURE_TABS.FACE_RECOGNITION:
        setFileFaceRecognition(file)
        return
      default:
        return
    }
  }

  const setStateReset = () => {
    switch (currentTab) {
      case FEATURE_TABS.ADD_NEW:
        setToggleReset_A(!toggleReset_A)
        return
      case FEATURE_TABS.FACE_RECOGNITION:
        setToggleReset_F(!toggleReset_F)
        return
      default:
        return
    }
  }

  const handleTestImage = () => {
    setFileFaceRecognition(null)
    setToggleReset_F(!toggleReset_F)
    setCurrentTab(FEATURE_TABS.FACE_RECOGNITION)
  }

  useEffect(() => {
    if (fileFaceRecognition && isTabFaceRecognition) {
      setLoading(true)
      setDetectAllFacesComplete(false)
    }
  }, [fileFaceRecognition])

  useEffect(() => {
    if (detectAllFacesComplete) {
      setLoading(false)
    }
  }, [detectAllFacesComplete])

  useEffect(() => {
    const loadModels = async () => {
      try {
        await faceapi.nets.ssdMobilenetv1.loadFromUri('/models')
        await faceapi.nets.faceLandmark68Net.loadFromUri('/models')
        await faceapi.nets.faceRecognitionNet.loadFromUri('/models')
        setLoadingFace(false)
      } catch (error) {
        console.log('error: ', error)
      }
    }

    loadModels()
  }, [])

  useEffect(() => {
    if (faces_A?.length > 0) {
      const newListInfo = Array(faces_A.length).fill({ ...initialValues })
      setListInfo_A(newListInfo)
      setFaceSelected_A(0)
    } else {
      setListInfo_A([])
    }
  }, [fileAddNew, faces_A])

  useEffect(() => {
    if (fileAddNew) {
      setLoading(true)
      intervalRef.current = setInterval(() => {
        setLoading(false)
        clearInterval(intervalRef.current)
      }, 3000)
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [fileAddNew])

  useEffect(() => {
    setFaces_A([])
    setListInfo_A([])
    setFacesExtracted_A(false)
  }, [toggleReset_A])

  useEffect(() => {
    setFaces_F([])
    setListInfo_F([])
    setFacesExtracted_F(false)
  }, [toggleReset_F])

  useEffect(() => {
    if (faces_F.length > 0) {
      handleFaceRecognition(faces_F)
    }
  }, [faces_F])

  const handleFaceRecognition = async (faces: HTMLCanvasElement[]) => {
    setIsLoading_F(true)
    Promise.all(
      faces.map((face) => {
        return canvasToFile(face, 'face.png').then((file) => {
          return dispatch(faceRecognitionAction({ params: { file } }))
        })
      })
    )
      .then((values) => {
        const personsResult = values.map((value) => {
          if (value.payload.data.errorCode) {
            return {
              ...initialValues,
              error: value.payload.data.errorCode,
            }
          }

          return value.payload.data.persons[0]
        })
        setListInfo_F(personsResult)
      })
      .catch((error) => {
        console.log('error: ', error)
      })
      .finally(() => {
        setLoading(false)
        setIsLoading_F(false)
      })
  }

  const handleTabs = (value: string) => {
    setCurrentTab(value)
  }

  const handleCloseWebcam = () => {
    setIsVisible(false)
  }

  const handleOpenWebcam = () => {
    setIsVisible(true)
  }

  const handleWebcam = (file: File) => {
    setStateFile(file)
    setIsVisible(false)
  }

  return (
    <>
      <div
        className="main-container custom-container"
        id="demo-face-recognition"
      >
        <h1 className="text-[46px] text-center font-bold">
          {t('Face Recognition')}
        </h1>
        <p className="text-center leading-6 mt-[18px] mb-[60px]">
          {t('Images processed in seconds using AI')}
        </p>

        <div className="scanner-body">
          <div className="scanner-top">
            <p
              className={classNames('tab-item', {
                disabled: hasLoading,
                active: isTabAddNewData,
              })}
              onClick={() => !hasLoading && handleTabs(FEATURE_TABS.ADD_NEW)}
            >
              {t('Add new data')}
            </p>
            <p
              className={classNames('tab-item', {
                disabled: hasLoading,
                active: isTabFaceRecognition,
              })}
              onClick={() =>
                !hasLoading && handleTabs(FEATURE_TABS.FACE_RECOGNITION)
              }
            >
              {t('Face Recognition')}
            </p>
          </div>
          <div className="wrapper-input">
            <h3 className="title">
              {isTabAddNewData ? t('Add new data') : t('Face Recognition')}
            </h3>
            <p className="description">
              {isTabAddNewData
                ? t('Add user data to the facial recognition system')
                : t(
                    'Upload images or videos for fast and accurate facial detection and recognition.'
                  )}
            </p>

            {((!fileAddNew && isTabAddNewData) ||
              (!fileFaceRecognition && isTabFaceRecognition)) &&
              !isLoading &&
              !loading && (
                <div className="form-upload">
                  {loadingFace ? (
                    <Loading loading={loadingFace} />
                  ) : (
                    <div className="box-actions">
                      <UploadDragger
                        webcam
                        textWebcam={
                          isTabAddNewData
                            ? t('Or can use webcam to add new data')
                            : t('Or can use webcam')
                        }
                        onWebcamAction={handleOpenWebcam}
                        className="w-[532px] h-[366px]"
                        icon={ImageUpload}
                        title={
                          isTabAddNewData
                            ? t('Drag and drop files here to upload new data')
                            : t('Drag and drop files here to upload')
                        }
                        accept={getAccept([...TYPE_FILE_IMAGE])}
                        onChange={(info) => {
                          setStateFile(info.file)
                        }}
                        onRemoveFile={() => setStateFile(null)}
                      />
                    </div>
                  )}
                </div>
              )}

            {loading && (
              <div className="wrapper-result loading">
                <div className="result-box">
                  <p className="loading-title">{t('Processing...')}</p>
                  <p className="loading-description">
                    {isTabFaceRecognition
                      ? t(
                          'Please wait while the face recognition data is being processed'
                        )
                      : t('Please wait while the data is being converted')}
                  </p>
                  <Loading loading={loading} />
                </div>
              </div>
            )}

            <div
              className={classNames('wrapper-tabs', {
                hidden:
                  (!fileAddNew && isTabAddNewData) ||
                  (!fileFaceRecognition && isTabFaceRecognition) ||
                  (!fileAddNew && !fileFaceRecognition) ||
                  loading,
              })}
            >
              <AddNewData
                reset={toggleReset_A}
                currentTab={currentTab}
                faces={faces_A}
                faceSelected={faceSelected_A}
                listInfo={listInfo_A}
                isLoading={isLoading_A}
                facesExtracted={facesExtracted_A}
                setFaces={setFaces_A}
                setFaceSelected={setFaceSelected_A}
                setListInfo={setListInfo_A}
                setIsLoading={setIsLoading_A}
                setFacesExtracted={setFacesExtracted_A}
                loadingFace={loadingFace}
                file={fileAddNew}
                setIsVisibleConfirm={setIsVisibleConfirm}
                handleTestImage={handleTestImage}
                setDetectAllFacesComplete={setDetectAllFacesComplete}
              />
              <FaceRecognition
                currentTab={currentTab}
                faces={faces_F}
                faceSelected={faceSelected_F}
                listInfo={listInfo_F}
                isLoading={isLoading_F}
                facesExtracted={facesExtracted_F}
                setFaces={setFaces_F}
                setFaceSelected={setFaceSelected_F}
                setFacesExtracted={setFacesExtracted_F}
                loading={loading}
                loadingFace={loadingFace}
                file={fileFaceRecognition}
                setIsVisibleConfirm={setIsVisibleConfirm}
                setDetectAllFacesComplete={setDetectAllFacesComplete}
              />
            </div>
          </div>
        </div>
        <p className="note-feature">
          <span>*</span>
          {t(
            'Your facial data will be stored for only 24 hours. After that, it will be deleted to ensure it is not used for any other purpose.'
          )}
        </p>
      </div>

      <ModalWebcam
        visible={isVisible}
        onClose={handleCloseWebcam}
        onOk={handleWebcam}
      />
      <Modal
        visible={isVisibleConfirm}
        onClose={() => setIsVisibleConfirm(false)}
        onOk={() => {
          handleTryAgain()
          setIsVisibleConfirm(false)
        }}
        title={t('Warning')}
        content={t(
          'Uploading data. Exiting now will lose progress. Are you sure want to exit?'
        )}
      />
    </>
  )
}

export default FaceRecognitionDemo
