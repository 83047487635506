import './FooterStyle.scss'

import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Logo from '@/assets/img/F-Logo.png'
import Calculator from '@/assets/svg/calculator.svg'
import FB from '@/assets/svg/fb.svg'
import IN from '@/assets/svg/in.svg'
import Locate from '@/assets/svg/locate.svg'
import X from '@/assets/svg/x.svg'
import YT from '@/assets/svg/yt.svg'

const Footer: React.FC = () => {
  const { t } = useTranslation(['common'])

  const [currentIndex, setCurrentIndex] = useState(8)

  const listOffices = [
    {
      name: 'Tokyo - Japan',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12963.825054326631!2d139.7830415!3d35.6780789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018893ded9202c1%3A0xd660f71a274d8e08!2z5qCq5byP5Lya56S-QkFQIFNvbHV0aW9uIEphcGFu!5e0!3m2!1sen!2s!4v1714961028007!5m2!1sen!2s"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'BAP tokyo',
        address: t('address_jp_tokyo'),
        phone: '(+81) 03 6280 4268',
        time: '10:00 AM - 7:00 PM',
        sub_time: t(`(Mon - Fri)`),
      },
    },
    {
      name: 'Osaka - Japan',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13123.45643706941!2d135.500226!3d34.683379!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e74ec4020f7d%3A0x3fae192877885a24!2sNomura%20Real%20Estate%20Building%2C%20Kansai%20Office!5e0!3m2!1sen!2sus!4v1715057211008!5m2!1sen!2sus"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'BAP osaka',
        address: t('address_jp_osaka'),
        phone: '(+81) 080 2399 7280',
        time: '10:00 AM - 7:00 PM',
        sub_time: t(`(Mon - Fri)`),
      },
    },
    {
      name: 'Seongnam - Korea',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12678.701103901929!2d127.112041!3d37.397511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5809d61f4917%3A0x4a951a1d0b47834a!2s914%20d08%2C%2014-2%20Pangyoyeok-ro%20192beon-gil%2C%20Bundang-gu%2C%20Seongnam-si%2C%20Gyeonggi-do%2C%20South%20Korea!5e0!3m2!1sen!2sus!4v1715057439698!5m2!1sen!2sus"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'BAP KOREA Inc.',
        address: t('address_kr_seongnam'),
        phone: '(+82) 10 9323 8279',
        time: '24/7',
      },
    },
    {
      name: 'DatumX - US agency',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13267.855091357113!2d-118.138501!3d33.761602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd304a34e13cf5%3A0x14954f1a7fdd2234!2s4712%202nd%20St%2C%20Long%20Beach%2C%20CA%2090803!5e0!3m2!1sen!2sus!4v1715057525178!5m2!1sen!2sus"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'DatumX, US agency',
        address: t('address_us_datumx'),
        phone: '+1 (800) 674-9525',
        time: '24/7',
      },
    },
    {
      name: 'Sydney - Australia',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13252.172726385314!2d151.211527!3d-33.862778!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae69c04d02c5%3A0x2e49481e352ffdbf!2sQuay%20Quarter!5e0!3m2!1svi!2sus!4v1719296517297!5m2!1svi!2sus"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          width="100%"
        />
      ),
      info: {
        name: 'BAP Australia',
        address: t('address_au_sydney'),
        phone: '(+61) 432 334 140',
        time: '24/7',
      },
    },
    {
      name: 'Ha Noi - Viet Nam',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14894.134592733286!2d105.800158!3d21.051338!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ad3fd2785ca9%3A0xadc44cfc0d73d528!2zNnRoIEVsZW1lbnQgVMOieSBI4buTIFTDonk!5e0!3m2!1sen!2sus!4v1715057147879!5m2!1sen!2sus"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'BAP ha noi',
        address: t('address_vn_hanoi'),
        phone: '(+84) 986 241 041',
        time: `8:15 AM - 5:30 PM`,
        sub_time: t(`(Mon - Fri)`),
      },
    },
    {
      name: 'Hue - Viet Nam',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3826.332520452968!2d107.591523!3d16.458692!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3141a138a9fdefeb%3A0x453098414556445a!2zVMOyYSBuaMOgIHbEg24gcGjDsm5nIEhDQywgMjggTMO9IFRoxrDhu51uZyBLaeG7h3QsIFbEqW5oIE5pbmgsIFRow6BuaCBwaOG7kSBIdeG6vywgVGjhu6thIFRoacOqbiBIdeG6vywgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2sus!4v1715057247580!5m2!1svi!2sus"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'BAP hue',
        address: t('address_vn_hue'),
        phone: '(+84) 02346 273 555',
        time: `8:15 AM - 5:30 PM`,
        sub_time: t(`(Mon - Fri)`),
      },
    },
    {
      name: 'Da Nang - Viet Nam',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15336.486282062737!2d108.205671!3d16.05918!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314219c5e4d20315%3A0xe7b92c5eb259bdc1!2zQkFQIEJ1aWxkaW5nIMSQw6AgTuG6tW5n!5e0!3m2!1sen!2sus!4v1715057476887!5m2!1sen!2sus"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'BAP DA NANG',
        address: t('address_vn_danang'),
        phone: '(+84) 0236 6565 115',
        time: `8:15 AM - 5:30 PM`,
        sub_time: t(`(Mon - Fri)`),
      },
    },
    {
      name: 'Ho Chi Minh - Viet Nam',
      iframe: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15676.675170271397!2d106.708217!3d10.798381!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175291146232ed5%3A0x922c6532b4696d5b!2sBAP%20Building!5e0!3m2!1sen!2sus!4v1715057708100!5m2!1sen!2sus"
          width="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      ),
      info: {
        name: 'BAP ho chi minh',
        address: t('address_vn_hcm'),
        phone: '(+84) 02862 701 557',
        time: `8:15 AM - 5:30 PM`,
        sub_time: t(`(Mon - Fri)`),
      },
    },
  ]

  const renderListOffices = listOffices.map((item, index) => (
    <div
      className={classNames('wrapper-office', {
        active: currentIndex === index,
      })}
      key={index}
      onClick={() => setCurrentIndex(index)}
    >
      <img src={Locate} alt="Locate office" />
      <p className="office-name">{item.name}</p>
    </div>
  ))

  return (
    <footer className="footer">
      <div className="f-top">
        <div className="main-container custom-container">
          <div className="wrapper-contents">
            <h2 className="title">{t('Global Presence')}</h2>
            <p className="description">{t('footer_description')}</p>
            <div className="list-offices">{renderListOffices}</div>
          </div>
          <div className="wrapper-maps">
            <div className="box-maps">
              <h3 className="office-name">
                {listOffices[currentIndex].info.name}
              </h3>
              <p className="office-info">
                {listOffices[currentIndex].info.address}
              </p>
              <p className="office-info">
                {listOffices[currentIndex].info.phone}
              </p>
              <p className="office-time-open">
                <img src={Calculator} alt="" />
                <span>{listOffices[currentIndex].info.time}</span>
                {listOffices[currentIndex].info.sub_time && (
                  <span className="sub-time">
                    {listOffices[currentIndex].info.sub_time}
                  </span>
                )}
              </p>

              <div className="map">{listOffices[currentIndex].iframe}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="f-bottom">
        <div className="main-container custom-container">
          <div className="wrapper-f-bottom">
            <div className="main-content">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="logo footer" />
                </Link>
              </div>
              <div className="social-network">
                <img
                  src={FB}
                  alt="FB"
                  onClick={() =>
                    window.open('https://www.facebook.com/bap32', '_blank')
                  }
                />
                <img
                  src={IN}
                  alt="IN"
                  onClick={() =>
                    window.open(
                      'https://www.linkedin.com/company/bap-it-jsc/posts/?feedView=all',
                      '_blank'
                    )
                  }
                />
                <img
                  src={X}
                  alt="X"
                  onClick={() =>
                    window.open('https://x.com/bapsoftware', '_blank')
                  }
                />
                <img
                  src={YT}
                  alt="YT"
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/@bapsoftware1020',
                      '_blank'
                    )
                  }
                />
              </div>
            </div>
            <div className="copyright">
              <p>Copyright © 2023 BAP IT CO., JSC. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
