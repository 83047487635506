import './style.scss'

import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import ImageSolution1 from '@/assets/img/blockchain/solution_1.svg'
import ImageSolution2 from '@/assets/img/blockchain/solution_2.svg'
import ImageSolution3 from '@/assets/img/blockchain/solution_3.svg'
import ImageSolution4 from '@/assets/img/blockchain/solution_4.svg'
import ImageSolution5 from '@/assets/img/blockchain/solution_5.svg'
import ImageSolution6 from '@/assets/img/blockchain/solution_6.svg'
import ImageSolution7 from '@/assets/img/blockchain/solution_7.svg'
import ImageSolution8 from '@/assets/img/blockchain/solution_8.svg'
import ImageSolution9 from '@/assets/img/blockchain/solution_9.svg'
import ImageSolution10 from '@/assets/img/blockchain/solution_10.svg'
import ImageSolution11 from '@/assets/img/blockchain/solution_11.svg'
import IconBtnNext from '@/assets/svg/btn_slick_next.svg'
import IconBtnNextColor from '@/assets/svg/btn_slick_next_color.svg'
import IconBtnPrev from '@/assets/svg/btn_slick_prev.svg'
import IconBtnPrevColor from '@/assets/svg/btn_slick_prev_color.svg'
import CardSolution from '@/components/Common/Item/CardSolution'

const Solution = () => {
  const { t } = useTranslation(['common'])
  const sliderRef = useRef<Slider | null>(null)

  const [currentSlide, setCurrentSlide] = useState(0)

  const listItems = [
    {
      image: ImageSolution1,
      title: t('blockchain_solution_title_1'),
      description: t('blockchain_solution_description_1'),
    },
    {
      image: ImageSolution2,
      title: t('blockchain_solution_title_2'),
      description: t('blockchain_solution_description_2'),
    },
    {
      image: ImageSolution3,
      title: t('blockchain_solution_title_3'),
      description: t('blockchain_solution_description_3'),
    },
    {
      image: ImageSolution4,
      title: t('blockchain_solution_title_4'),
      description: t('blockchain_solution_description_4'),
    },
    {
      image: ImageSolution5,
      title: t('blockchain_solution_title_5'),
      description: t('blockchain_solution_description_5'),
    },
    {
      image: ImageSolution6,
      title: t('blockchain_solution_title_6'),
      description: t('blockchain_solution_description_6'),
    },
    {
      image: ImageSolution7,
      title: t('blockchain_solution_title_7'),
      description: t('blockchain_solution_description_7'),
    },
    {
      image: ImageSolution8,
      title: t('blockchain_solution_title_8'),
      description: t('blockchain_solution_description_8'),
    },
    {
      image: ImageSolution9,
      title: t('blockchain_solution_title_9'),
      description: t('blockchain_solution_description_9'),
    },
    {
      image: ImageSolution10,
      title: t('blockchain_solution_title_10'),
      description: t('blockchain_solution_description_10'),
    },
    {
      image: ImageSolution11,
      title: t('blockchain_solution_title_11'),
      description: t('blockchain_solution_description_11'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <CardSolution
        key={index}
        image={item.image}
        title={item.title}
        description={item.description}
      />
    )
  })

  return (
    <section className="blockchain_solution" id="blockchain_solution">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">
            {t('Discover the Power of Our Blockchain Solutions')}
          </h2>
          <div className="box-description">
            <p className="description">
              {t('blockchain_solution_description')}
            </p>

            <div className="slider-action">
              <button
                className="btn-slick btn-left"
                disabled={currentSlide === 0}
                onClick={() => sliderRef.current?.slickPrev()}
              >
                {currentSlide === 0 ? (
                  <img src={IconBtnPrev} alt="slide button" />
                ) : (
                  <img src={IconBtnPrevColor} alt="slide button" />
                )}
              </button>
              <button
                className="btn-slick btn-right"
                disabled={currentSlide >= renderListItems.length - 4}
                onClick={() => sliderRef.current?.slickNext()}
              >
                {currentSlide >= renderListItems.length - 4 ? (
                  <img src={IconBtnNext} alt="slide button" />
                ) : (
                  <img src={IconBtnNextColor} alt="slide button" />
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="list-cards">
          <Slider
            className="slier-solution"
            ref={sliderRef}
            dots={false}
            infinite={false}
            speed={500}
            slidesToShow={4}
            slidesToScroll={1}
            autoplay={false}
            beforeChange={(current, next) => setCurrentSlide(next)}
            responsive={[
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {renderListItems}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default Solution
